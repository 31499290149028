const sidebarSections = [
	{
		id: 'dash',
		app: 'dash',
		label: 'REPORTS',
		sectionGroup: true,
		links: [
			{
				id: 'dash_welcome',
				label: 'My Dashboard',
				url: 'dash'
			},
			{
				id: 'dashboards',
				label: 'My Reports',
				url: 'dash/reports'
			},
			{
				id: 'dash_templates',
				label: 'Report Templates',
				url: 'dash/templates'
			},
			{
				id: 'dash_template_form',
				label: 'Create Template',
				url: 'dash/template/new'
			},
			{
				id: 'scheduled_exports',
				label: 'Scheduled Exports',
				url: 'scheduled-exports'
			},
			{
				id: 'download_exports',
				label: 'Download Exports',
				url: 'download-exports'
			},
			{
				id: 'dash_delivered_reports',
				label: 'Delivered Reports',
				url: 'dash/delivered-reports'
			},
			{
				id: 'dash_powerbi_scopes',
				label: 'BI Report Scopes',
				url: 'dash/bi-report/scopes'
			},
		]
	},
	{
		id: 'ppc',
		app: 'ppc',
		label: 'PPC PERFORMANCE',
		sectionGroup: true,
		links: [
			{
				id: 'ppc_initiatives',
				label: 'Initiatives',
				url: 'ppc/initiatives',
				metadata: 'group groups'
			},
			{
				id: 'ppc_publishers',
				label: 'Publishers',
				url: 'ppc/publishers',
				metadata: 'account accounts'
			},
			{
				id: 'ppc_campaigns',
				label: 'Campaigns',
				url: 'ppc/campaigns'
			},
			{
				id: 'ppc_adgroups',
				label: 'Ad Groups',
				url: 'ppc/ad-groups',
				metadata: 'adgroup adgroups'
			},
			{
				id: 'ppc_keywords',
				label: 'Keywords',
				url: 'ppc/keywords'
			},
			{
				id: 'ppc_adcopies',
				label: 'Ad Copy',
				url: 'ppc/ad-copy',
				metadata: 'adcopy adcopies copies'
			},
			{
				id: 'ppc_devices',
				label: 'Device Trends',
				url: 'ppc/devices'
			},
			{
				id: 'ppc_date_performance',
				label: 'Date Performance',
				url: 'ppc/date-performance'
			},
			{
				id: 'ppc_keywordoverlap',
				label: 'Keyword Overlap',
				url: 'ppc/keyword-overlap'
			}
		]
	},
	{
		id: 'seo',
		app: 'seo',
		label: 'SEO PERFORMANCE',
		sectionGroup: true,
		links: [
			{
				label: 'Group Comparison',
				sectionGroup: true,
				links: [
					{
						id: 'seo_keyword_groups',
						label: 'Keyword Groups',
						url: 'seo/keyword-groups',
						metadata: 'keyword keywords'
					},
					{
						id: 'seo_url_parent_groups',
						label: 'Parent Groups',
						url: 'seo/url-parent-groups',
						metadata: 'url urls page pages'
					},
					{
						id: 'seo_url_groups',
						label: 'URL Groups',
						url: 'seo/url-groups',
						metadata: 'url urls page pages'
					}
				]
			},
			{
				id: 'seo_keywords',
				label: 'Keywords by Buyer Journey',
				url: 'seo/keywords',
				metadata: 'see think do care intent intents'
			},
			{
				id: 'seo_urls',
				label: 'Page Performance',
				url: 'seo/urls',
				metadata: 'url urls page pages'
			},
			{
				label: 'Visibility',
				sectionGroup: true,
				metadata: 'rank ranks visibility',
				links: [
					{
						id: 'seo_ranks_keywords',
						label: 'All Ranks',
						url: 'seo/ranks',
						metadata: 'intent intents'
					},
					{
						id: 'seo_ranks_keyword_groups',
						label: 'Keyword Group Ranks',
						url: 'seo/keyword-group-ranks'
					},
					{
						id: 'seo_ranks_url_parent_groups',
						label: 'Parent Group Ranks',
						url: 'seo/parent-group-ranks',
						metadata: 'url urls'
					},
					{
						id: 'seo_ranks_url_groups',
						label: 'URL Group Ranks',
						url: 'seo/url-group-ranks',
						metadata: 'url urls'
					}
				]
			},
			{
				id: 'seo_emv',
				label: 'Value of SEO',
				url: 'seo/value-of-seo',
				metadata: 'emv estimate estimated estimates market markets intent intents'
			},
			{
				id: 'seo_engines',
				label: 'Search Engines',
				url: 'seo/engines'
			},
			{
				id: 'seo_date_performance',
				label: 'Date Performance',
				url: 'seo/date-performance'
			},
			{
				id: 'seo_base_performance',
				label: 'Explore All Data',
				url: 'seo/all-data',
				metadata: 'keyword keywords url urls engine engines 3d dimension dimensions intent intents rank ranks'
			}
		]
	},
	{
		id: 'seo_opportunities',
		app: 'seo',
		label: 'SEO OPPORTUNITIES',
		sectionGroup: true,
		links: [
			{
				id: 'seo_keyword_go_topics',
				label: 'Analyze Topics',
				url: 'content-go/analyze-topics',
				metadata: 'go kgo content'
			},
			{
				id: 'seo_keyword_go_topic_keywords',
				label: 'Topic Keywords',
				url: 'content-go/topic-keywords',
				metadata: 'go kgo content'
			},
			{
				id: 'seo_top_ranking_urls',
				label: 'Prioritize Keywords',
				url: 'seo/prioritize-keywords',
				metadata: '3d dimension dimensions rank ranks visibility top intent intents'

			},
			{
				id: 'seo_keyword_go_manage_blacklist',
				label: 'Manage Blacklist',
				url: 'content-go/manage-blacklist',
				metadata: 'go kgo content'
			},
			{
				id: 'seo_keyword_go_view_blacklist',
				label: 'View Blacklist',
				url: 'content-go/view-blacklist',
				metadata: 'go kgo content'
			},
			{
				id: 'seo_kgo_new_topic',
				label: 'Request New Topic',
				url: 'content-go/new-topic',
				metadata: 'go kgo content'
			},
		]
	},
	{
		id: 'synergy',
		app: 'syn',
		label: 'PPC + SEO SYNERGY',
		sectionGroup: true,
		links: [
			{
				id: 'synergy',
				label: 'PPC + SEO Synergy',
				url: 'synergy',
				metadata: 'keyword keywords'
			}
		],
	},
]

function getNavSections(labelOverrides, navigationLabels, blacklistedViews, isFs2020) {
	let results = []
	let emptyContainer = true
	sidebarSections.forEach((parent) => {
		parent.label = labelOverrides[parent['id']] || navigationLabels[parent['id']] || parent.label
		let links = []
		parent.links.forEach((child) => {
			if (blacklistedViews.indexOf(child.id) < 0) {
				emptyContainer = false
				if (child.links) {
					let childLinks = []
					child.links.forEach((grandchild) => {
						if (blacklistedViews.indexOf(grandchild.id) < 0) {
							grandchild.label = labelOverrides[grandchild['id']] || navigationLabels[grandchild['id']] || grandchild.label
							childLinks.push(grandchild)
						}
					})
					child.links = childLinks
					if (isFs2020) {
						child.links = swapLink(child.links, 'seo_keyword_groups', 'seo_url_groups')
					}
				} else {
					child.label = labelOverrides[child['id']] || navigationLabels[child['id']] || child.label
				}
				links.push(child)
			}
		})
		parent.links = links
		if (isFs2020) {
			parent.links = swapLink(parent.links, 'seo_keywords', 'seo_urls')
		}
		if (!emptyContainer) {
			results.push(parent)
		}
		emptyContainer = true
	})

	return results
}

function swapLink(links, linkId1, linkId2) {
	let reorderedLinks = []
	let pos1 = -1
	let pos2 = -1
	let link1 = null
	let link2 = null
	links.forEach((link, idx) => {
		if (link.id === linkId1) {
			pos1 = idx
			link1 = link
		}
		if (link.id === linkId2) {
			pos2 = idx
			link2 = link
		}
		reorderedLinks.push(link)
	})

	if (pos1 >= 0) {
		if (link2) {
			reorderedLinks[pos1] = link2
		}
	}
	if (pos2 >= 0) {
		if (link1) {
			reorderedLinks[pos2] = link1
		}
	}

	return reorderedLinks
}

const allSectionsRoutes = {}

const viewTopSectionData = {}

function getBlacklistedApps(userAppsBlacklisted) {
	if (userAppsBlacklisted != null && userAppsBlacklisted.length > 0) {
		return userAppsBlacklisted
	}

	return ['']
}

function getBlacklistedViews(userViewsBlacklisted) {
	if (userViewsBlacklisted != null && userViewsBlacklisted.length > 0) {
		return userViewsBlacklisted
	}

	return ['']
}

function getNavigationTreeFromSource() {}

function createBreadcrumbItem(title, url) {
	let breadcrumbItem = {
		title,
		url
	}

	return breadcrumbItem
}

function getBreadcrumbFromPath(navId, title, container) {
	let breadcrumbs = []
	const _sidebarSections = sidebarSections
	for (let i in _sidebarSections) {
		if (_sidebarSections[i].id === container) {
			let selectedContainer = _sidebarSections[i]

			breadcrumbs.push(createBreadcrumbItem(selectedContainer.label, selectedContainer.id))

			if (selectedContainer.links != null) {
				for (let j in selectedContainer.links) {
					let menuItem = selectedContainer.links[j]
					if (menuItem.id != null) {
						if (menuItem.id === navId) {
							breadcrumbs.push(createBreadcrumbItem(menuItem.label, menuItem.id))
							break
						}
					} else if (
						menuItem.sectionGroup != null &&
						menuItem.sectionGroup === true &&
						menuItem.links != null
					) {
						for (let k in menuItem.links) {
							let menuSubItem = menuItem.links[k]
							if (menuSubItem.id != null) {
								if (menuSubItem.id === navId) {
									breadcrumbs.push(createBreadcrumbItem(menuItem.label, menuItem.id))
									breadcrumbs.push(createBreadcrumbItem(menuSubItem.label, menuSubItem.url))
									break
								}
							}
						}
					}
				}
			}
		}
	}

	return breadcrumbs
}

function getLinkObjectFromKey(navId, blacklist) {
	const _sidebarSections = sidebarSections
	let app = null
	let parentLabel = null
	let parentId = null
	let blacklistedApps = blacklist.apps
	let blacklistedViews = blacklist.views
	if (blacklistedViews.indexOf(navId) >= 0) {
		return null
	}

	for (let i in _sidebarSections) {
		let selectedContainer = _sidebarSections[i]
		if (selectedContainer.links != null) {
			app = selectedContainer.app
			parentLabel = selectedContainer.label
			parentId = selectedContainer.id
			for (let j in selectedContainer.links) {
				let menuItem = selectedContainer.links[j]
				if (menuItem.id != null) {
					if (menuItem.id === navId) {
						if (blacklistedApps.indexOf(app) >= 0) {
							return null
						}
						menuItem.app = app
						menuItem.parentLabel = parentLabel
						menuItem.parentId = parentId
						return menuItem
					}
				} else if (
					menuItem.sectionGroup != null &&
					menuItem.sectionGroup === true &&
					menuItem.links != null
				) {
					for (let k in menuItem.links) {
						let menuSubItem = menuItem.links[k]
						if (menuSubItem.id != null) {
							if (menuSubItem.id === navId) {
								if (blacklistedApps.indexOf(app) >= 0) {
									return null
								}
								menuSubItem.app = app
								menuSubItem.parentLabel = parentLabel
								menuSubItem.parentId = parentId
								return menuSubItem
							}
						}
					}
				}
			}
		}
	}

	return null
}

function getNavigationSearchResults(blacklistedApps, blacklistedViews) {
	let results = []
	sidebarSections.forEach((parent) => {
		if (blacklistedApps.indexOf(parent['app']) > -1) {
			return
		}
		if (parent['links']) {
			parent['links'].forEach((child) => {
				if (child['links']) {
					child['links'].forEach((grandchild) => {
						if (blacklistedViews.indexOf(grandchild['id']) > -1) {
							return
						}
						results.push({
							labels: [parent.label, child.label, grandchild.label],
							url: grandchild.url,
							key: grandchild.id,
							title: grandchild.id,
							metadata: `${parent.label} ${parent.metadata} ${child.label} ${child.metadata} ${grandchild.label} ${grandchild.metadata}`,
						})
					})
				} else {
					if (blacklistedViews.indexOf(child['id']) > -1) {
						return
					}
					results.push({
						labels: [parent.label, child.label],
						url: child.url,
						key: child.id,
						title: child.id,
						metadata: `${parent.label} ${parent.metadata} ${child.label} ${child.metadata}`,
					})
				}	
			})
		} else {
			results.push({
				labels: [parent.label],
				url: parent.url,
				key: parent.id,
				title: parent.id,
				metadata: `${parent.label} ${parent.metadata}`,
			})
		}
	})
	return results
}

/**
 * Export function expected by map-stream.
 */
module.exports = {
	sidebarSections: sidebarSections,
	getNavSections: getNavSections,
	getNavigationSearchResults: getNavigationSearchResults,
	getBlacklistedApps: getBlacklistedApps,
	getBlacklistedViews: getBlacklistedViews,
	viewTopSectionData: viewTopSectionData,
	allSectionsRoutes: allSectionsRoutes,
	getBreadcrumbFromPath: getBreadcrumbFromPath,
	getLinkObjectFromKey: getLinkObjectFromKey,
}

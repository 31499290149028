import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'

import { Form, Button, Input, Container, Divider, Grid } from 'semantic-ui-react'
class Maintenance extends React.Component {
	constructor(props) {
		super(props)

		this.clientLogo = props.sessionData.configuration.logo_image || ''
	}

	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<Grid centered columns={3} verticalAlign="middle" className="maintenance-box">
					<Grid.Column verticalAlign="middle">
						<div className="nonav-template-content">
							<div className="content">
								<Container>
									<div className="maintenance">
										<div className="info">
											<div className="logo-container">
												<div className="client-logo">
													<img class="logo" src={this.clientLogo} border="0" />
												</div>
											</div>
											<div className="description-container">
												<h4>Temporarily Down For Maintenance</h4>
											</div>
										</div>
										<Divider />
										<div className="form-container">
											<span className="body-negative">
												We are performing scheduled maintenance. We should be back online shortly If
												you have any questions or concerns, please email
											</span>
											<div className="email-container">
												<a href="mailto:support@keywordobjects.com">support@keywordobjects.com</a>
											</div>
										</div>
									</div>
								</Container>
							</div>
						</div>
					</Grid.Column>
				</Grid>
				<Footer />
			</React.Fragment>
		)
	}
}

export default Maintenance

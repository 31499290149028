import React from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import NavigationLink from '../NavigationLink/NavigationLink'

class NavigationSubSection extends React.Component {
	constructor(props) {
		super(props)
	}

	state = { activeIndex: 0, isOpen: this.isOpen() }

	handleClick = (e, titleProps) => {
		const { index } = titleProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index

		this.setState({ activeIndex: newIndex, isOpen: !this.state.isOpen })
	}

	isOpen() {
		let isOpen = false
		this.props.sectionLinks.map(link => {
			if (this.props.sectionSelectedId === link.id) {
				isOpen = true
			}
		})
		return isOpen
	}

	render() {
		const { activeIndex } = this.state
		let selectedId = this.props.sectionSelectedId

		return (
			<Accordion className="navigation-subsection">
				<Accordion.Title
					className="navigation-subsection-title-container"
					active={this.state.isOpen}
					index={0}
					onClick={this.handleClick}
				>
					<div className="navigation-subsection-icon">
						<Icon name="dropdown" />
					</div>
					<div className="navigation-subsection-title noselect">{this.props.sectionTitle}</div>
				</Accordion.Title>
				<Accordion.Content active={this.state.isOpen}>
					{this.props.sectionLinks.map(function(sectionLink, index) {
						let selected = sectionLink.id === selectedId ? true : false
						//console.log('sectionLink:selected', selected, sectionLink.id, selectedId, sectionLink)
						return (
							<NavigationLink
								type="small"
								key={sectionLink.label}
								href={sectionLink.url}
								sectionActive={selected}
							>
								{sectionLink.label}
							</NavigationLink>
						)
					})}
				</Accordion.Content>
			</Accordion>
		)
	}
}

NavigationSubSection.propTypes = {
	sectionTitle: PropTypes.string,
	sectionLinks: PropTypes.array,
	sectionSelectedId: PropTypes.string
}

NavigationSubSection.defaultProps = {
	sectionTitle: '',
	sectionLinks: [],
	sectionSelectedId: ''
}

export default NavigationSubSection

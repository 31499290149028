import koApi from 'ko_core/helpers/api'

function getForgotPassService(params, endpoint) {
	//ModelLocator.configuration.controller
	var url = 'https://' + window.location.host + '/' + 'index' + '.php/resetPassword'
	//return koApi.sendPostQueryService(url, koApi.getParamsToJSON(params))
	return koApi.sendPostFormService(url, params)
}

function submitNewPasswordService(params, endpoint) {
	//ModelLocator.configuration.controller
	var url = 'https://' + window.location.host + '/' + 'index' + '.php/forgot-password'
	//return koApi.sendPostQueryService(url, koApi.getParamsToJSON(params))
	return koApi.sendPostFormService(url, params)
}

export const NonavApi = {
	getForgotPassService: getForgotPassService,
	submitNewPasswordService: submitNewPasswordService,
}

const moment = require('moment')

const apps = {
	seo: 'SEO',
	ppc: 'PPC',
	syn: 'Synergy',
}

function getApp(str, wantKey = false) {
	for (var i in apps) {
		if (str === i || str === apps[i]) {
			return wantKey ? i : apps[i]
		}
	}

	return 'seo'
}

function getUIDSection(length = 4) {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

function getUID() {
	return (
		getUIDSection() +
		getUIDSection() +
		'-' +
		getUIDSection() +
		'-' +
		getUIDSection() +
		'-' +
		getUIDSection() +
		'-' +
		getUIDSection() +
		getUIDSection() +
		getUIDSection()
	)
}

function getDropdownItems(items, idKey = 'id') {
	let dropdownOptions = []
	for (let i = 0; i < items.length; i++) {
		let option = {}
		option.text = items[i].label
		option.value = items[i][idKey]
		dropdownOptions.push(option)
	}

	return dropdownOptions
}

const datesPresets = {
	yesterday: 'yesterday',
	thisweek: 'thisweek',
	last7days: 'last7days',
	lastweek: 'lastweek',
	monthtodate: 'monthtodate',
	last30days: 'last30days',
	lastmonth: 'lastmonth',
	ytd: 'ytd',
	lastyear: 'lastyear',
	custom: 'custom',
	previousperiod: 'previousperiod',
	previousweek: 'previousweek',
	previousmonth: 'previousmonth',
	previousyear: 'previousyear',
	previouscustom: 'previouscustom'
}

function calculatePreset(preset, startDate, endDate) {
	let newStartDate = null
	let newEndDate = null
	switch (preset) {
		case datesPresets.yesterday:
			newStartDate = moment().subtract(1, 'days')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.thisweek:
			newStartDate = moment().startOf('week')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.last7days:
			newStartDate = moment().subtract(7, 'days')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.lastweek:
			newStartDate = moment()
				.startOf('week')
				.subtract(6, 'days')
			newEndDate = moment(newStartDate).add(6, 'days')
			break

		case datesPresets.monthtodate:
			newStartDate = moment().startOf('month')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.last30days:
			newStartDate = moment().subtract(30, 'days')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.lastmonth:
			newStartDate = moment()
				.startOf('month')
				.subtract(1, 'month')
			newEndDate = moment()
				.startOf('month')
				.subtract(1, 'day')
			break

		case datesPresets.ytd:
			newStartDate = moment().startOf('year')
			newEndDate = moment().subtract(1, 'days')
			break

		case datesPresets.lastyear:
			newStartDate = moment()
				.startOf('year')
				.subtract(1, 'year')
			newEndDate = moment()
				.startOf('year')
				.subtract(1, 'days')
			break

		case datesPresets.previousperiod:
			let baseStartDate = moment(startDate)
			let baseEndDate = moment(endDate)
			let baseDiff = baseEndDate.diff(baseStartDate, 'days')
			newEndDate = moment(baseStartDate).subtract(1, 'days')
			newStartDate = moment(newEndDate).subtract(baseDiff, 'days')
			break

		case datesPresets.previousweek:
			newStartDate = moment(startDate).subtract(7, 'days')
			newEndDate = moment(endDate).subtract(7, 'days')
			break

		case datesPresets.previousmonth:
			let diff = moment(endDate).diff(moment(startDate), 'days')
			newEndDate = moment(endDate).subtract(1, 'months')
			newStartDate = moment(startDate).subtract(1, 'months')
			break

		case datesPresets.previousyear:
			newStartDate = moment(startDate).subtract(1, 'years')
			newEndDate = moment(endDate).subtract(1, 'years')
			break

		default:
			newStartDate = startDate
			newEndDate = endDate
	}

	return {
		newStartDate: newStartDate,
		newEndDate: newEndDate
	}
}

export default {
	getApp: getApp,
	getDropdownItems: getDropdownItems,
	getUID: getUID,
	datesPresets: datesPresets,
	calculatePreset: calculatePreset
}

import React from 'react'
import PropTypes from 'prop-types'
import { Item } from 'semantic-ui-react'

const AccountRep = (props) => {
	return (
			<Item.Group>
				<Item>
					<Item.Image size='tiny' className="rep-avatar-container" src={props.accountRep.avatar} />
					<Item.Content>
						<Item.Header>{props.accountRep.first_name} {props.accountRep.last_name}</Item.Header>
						<Item.Meta>KO Representative</Item.Meta>
						<Item.Description as='a' href={`mailto:${props.accountRep.email}`}>
							{props.accountRep.email}
						</Item.Description>
						<Item.Extra>Phone: {props.accountRep.phone_number}</Item.Extra>
					</Item.Content>
				</Item>
			</Item.Group>
	)
}

AccountRep.propTypes = {
	accountRep: PropTypes.object.isRequired
}

AccountRep.defaultProps = {
	accountRep: {}
}

export default AccountRep

import React from 'react'
import SimpleLink from '../../atoms/SimpleLink/Simplelink'

const Footer = (props) => {
	let yr = new Date().getFullYear()
	return (
		<div className="footer-container">
			<div className="footer-left">
				<SimpleLink href={'https://www.acronym.com'} target="_blank" className="caption1">
					Acronym Media
				</SimpleLink>
				<SimpleLink href={'https://www.keywordobjects.com'} target="_blank" className="caption1">
					About Keyword Objects™
				</SimpleLink>
				<SimpleLink href={'http://www.acronym.com/privacy-policy/'} target="_blank" className="caption1">
					Privacy
				</SimpleLink>
				<SimpleLink href={'http://www.acronym.com/terms-of-use/'} target="_blank" className="caption1">
					Terms
				</SimpleLink>
			</div>

			<div className="footer-right">Copyright © {yr} Acronym Media, Inc. All rights reserved</div>
		</div>
	)
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import Navigation from '../Navigation/Navigation'

class NavigationContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			sections: this.getNavigationSource(
				props.sectionsSource,
				props.blacklistedApps,
				props.blacklistedViews
			),
			sectionsOpened: [this.props.selectedSectionId]
		}

		this.onSectionOpenClick = this.onSectionOpenClick.bind(this)
	}

	getNavigationSource(sourceSections, blacklistedApps, blacklistedViews) {
		//console.log('->', sourceSections)
		let filteredSections = []
		sourceSections.map((section) => {
			if (this.isBlacklistedApp(section.app, blacklistedApps) !== true) {
				let filteredSection = this.filterSectionViews(section, blacklistedViews)
				filteredSections.push(filteredSection)
			}
			return null
		})

		return filteredSections
	}

	isBlacklistedApp(app, blacklist) {
		let isBlacklisted = false
		blacklist.map((blacklistedApp) => {
			if (app === blacklistedApp) {
				isBlacklisted = true
			}
			return null
		})
		//console.log('isBApp', app, blacklist, isBlacklisted)
		return isBlacklisted
	}

	isViewBlacklisted(app, view, blacklist) {
		let isBlacklisted = false
		blacklist.map((blacklistedView) => {
			if (view === blacklistedView) {
				isBlacklisted = true
			}
			return null
		})
		//console.log('isViewBlck', isBlacklisted, `${app}_${view}`, app, view, blacklist)
		return isBlacklisted
	}

	filterSectionViews(section, blacklistedView) {
		let newSection = {}
		newSection.id = section.id
		newSection.label = section.label
		if (section.sectionGroup) {
			newSection.sectionGroup = section.sectionGroup
		}
		if (section.links) {
			newSection.links = this.getSectionViews(section.id, section.links, blacklistedView)
		}
		if (section.url) {
			newSection.url = section.url
		}

		return newSection
	}

	getSectionViews(appID, views, blacklistedView) {
		let filteredViews = []
		views.map((view) => {
			if (this.isViewBlacklisted(appID, view.id, blacklistedView) !== true) {
				if (view.id === undefined && view.links !== undefined) {
					//subsection
					let newSubsection = {}
					newSubsection.label = view.label
					newSubsection.links = this.getSectionViews(appID, view.links, blacklistedView)
					if (newSubsection.links.length > 0) {
						filteredViews.push(newSubsection)
					}
				} else {
					//view is not on the blacklist and is not a subsection
					filteredViews.push(view)
				}
			}
			return null
		})
		//console.log('getSectionViews', filteredViews, appID, views)
		return filteredViews
	}

	onSectionOpenClick(sectionId, active) {
		if (sectionId === this.props.selectedSectionId) {
			return
		}
		let sectionsOpened = [this.props.selectedSectionId]
		if (!active) {
			sectionsOpened.push(sectionId)
		}

		this.setState({
			sectionsOpened
		})
	}

	render() {
		return (
			<div className="header-navigation">
				<Navigation
					sections={this.state.sections}
					sectionsOpen={this.state.sectionsOpened}
					selectedSectionId={this.props.selectedSectionId}
					sectionLinkSelectedId={this.props.sectionLinkSelectedId}
					sectionOpenClickHandler={this.onSectionOpenClick}
					blacklistedApps={this.props.blacklistedApps}
					blacklistedViews={this.props.blacklistedViews}
					logoImage={this.props.logoImage}
				/>
			</div>
		)
	}
}

NavigationContainer.propTypes = {
	sectionsSource: PropTypes.array,
	blacklistedApps: PropTypes.array,
	blacklistedViews: PropTypes.array,
	selectedSectionId: PropTypes.string,
	sectionLinkSelectedId: PropTypes.string,
	logoImage: PropTypes.string
}

NavigationContainer.defaultProps = {
	sectionsSource: [],
	blacklistedApps: [],
	blacklistedViews: [],
	selectedSectionId: '',
	sectionLinkSelectedId: '',
	logoImage: ''
}

export default NavigationContainer

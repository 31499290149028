import configHelper from '../viewsConfigHelper'
import Login from './login/login'
import CrossSiteLogin from './login/CrossSiteLogin'

import Download from './download/download'
import ForgotPassword from './forgotpassword/forgotpassword'
import ForgotPasswordLanding from './forgotpassword/forgotpasswordlanding'
import Maintenance from './maintenance/maintenance'
import Termsofservice from './termsofservice/termsofservice'
import Privacy from './privacy/privacy'
import ProductSeo from './product/seo/ProductSeo'
import ProductPpc from './product/ppc/ProductPpc'

let mainApp = ''

//view component list
let componentList = {
	Login: Login,
	CrossSiteLogin: CrossSiteLogin,
	Download: Download,
	ForgotPassword: ForgotPassword,
	ForgotPasswordLanding: ForgotPasswordLanding,
	Maintenance: Maintenance,
	Termsofservice: Termsofservice,
	Privacy: Privacy,
	ProductSeo: ProductSeo,
	ProductPpc: ProductPpc
}

//views routes
let appRoutes = [
	{
		fullpath: 'login',
		component: 'Login'
	},
	{
		fullpath: 'login/:parameters/:public_key',
		component: 'CrossSiteLogin'
	},
	{
		fullpath: 'reset-password',
		component: 'ForgotPassword'
	},
	{
		fullpath: 'forgot-password*',
		component: 'ForgotPasswordLanding'
	},
	{
		fullpath: '*download-launcher/*',
		component: 'Download'
	},
	{
		fullpath: 'maintenance.php',
		component: 'Maintenance'
	},
	{
		fullpath: 'privacy',
		component: 'Privacy'
	},
	{
		fullpath: 'terms-of-service',
		component: 'Termsofservice'
	},
	{
		fullpath: 'products/seo',
		component: 'ProductSeo'
	},
	{
		fullpath: 'products/ppc',
		component: 'ProductPpc'
	}
]

function getAppConfig() {
	return configHelper.getAppConfig(componentList, appRoutes, mainApp)
}

export default {
	getAppConfig: getAppConfig
}

import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const SubdomainDropdown = (props) => {
	function handleSubdomainChange(e, target) {
		const url = target.options[target.value].url
		var domain = url.replace('http://','').replace('https://','').split(/[/?#]/)[0]
		var currentDomain = window.location.href.replace('http://','').replace('https://','').split(/[/?#]/)[0]

		if (domain !== currentDomain) {
			window.location = url
		}
	}

	return (
		<Dropdown
			onChange={handleSubdomainChange}
			onClick={props.onClick}
			onClose={props.onClose}
			options={props.subdomains}
			placeholder='eee'
			open={props.open}
			defaultValue={props.selected}
		/>
	)
}

SubdomainDropdown.propTypes = {
	selected: PropTypes.string,
	subdomains: PropTypes.array,
}

SubdomainDropdown.defaultProps = {
	selected: '',
	subdomains: []
}

export default SubdomainDropdown

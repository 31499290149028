import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import { Segment } from 'semantic-ui-react'

class ProductSeo extends React.Component {
	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<div className="template-content">
					<div className="content">
						<div className="product-container-area-holder">
							<div className="product-description">
								<h1>ENTERPRISE SEO WITH KEYWORD OBJECTS</h1>
								<div className="main-description">
									<span>
										How many hours have you spent trying to pull disparate data sets together to get
										a{' '}
										<b>
											full picture of SEO/PPC synergy, individual keyword performance, and goal
											fulfillment
										</b>
										across multiple channels? How about which SEO recommendations have the largest
										impact on your SEO revenue?
									</span>

									<span>
										40% of daily organic keywords have never been encountered before.{' '}
										<b>Your customers are dynamic, and so are your keywords.</b> Stop managing
										keyword lists. Once you've achieved organic ranking visibility, once you're
										attracting thousands, even millions, of weekly unique views across dozens of web
										properties, once you've tackled your major keyword targets, then what? Keyword
										lists limit your potential to find new opportunities. With Keyword Objects, you
										view <b>all your keywords</b>, better understand intent and gain intelligence
										from the new ways your customer use to find you.
										<b>
											full picture of SEO/PPC synergy, individual keyword performance, and goal
											fulfillment
										</b>
										across multiple channels? How about which SEO recommendations have the largest
										impact on your SEO revenue?
									</span>

									<div className="details">
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Built for Enterprise Brands</div>
												<span>
													KO allows for customization and depth. For example, KO's account structure
													mirrors your organizational hierarchy whether that be based on countries,
													regions, web properties, keywords or URLs.
												</span>
											</div>
										</div>
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">True Integration with Analytics</div>
												<span>
													With true integration, keywords are dynamic, and changes in rank are
													directly correlated with traffic and conversions - providing custom
													segmentation and multidimensional views.
												</span>
											</div>
										</div>
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Global Engines with Universal Search</div>
												<span>
													Acronym's track record with large enterprise Clients, means that Keyword
													Objects can manage global efforts that span 100s of countries, double byte
													languages and universal search.
												</span>
											</div>
										</div>
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Unlimited Data Source Integration</div>
												<span>
													KO's flexible data infrastructure allows for customization and integration
													with the data meaningful to you - from Adobe to SEOMoz to CRM systems and
													almost any data set you can think of.
												</span>
											</div>
										</div>
									</div>
								</div>

								<Segment className="download-segment">
									<a href="https://d1qrtv1cutlyu7.cloudfront.net/ko-enterprise-seo.pdf">
										<div className="download-container">
											<div className="ko-icon icon-download" />
											<div>
												<span>Download Our Product review</span>
											</div>
										</div>
									</a>
								</Segment>
							</div>
							<div className="product-video-container">
								<h2>Drive better results with search intelligence</h2>
								<div>
									<iframe
										src="https://player.vimeo.com/video/62383774?title=0&amp;byline=0&amp;portrait=1&amp;color=f15822"
										width="460"
										height="282"
										frameborder="0"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowfullscreen=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</React.Fragment>
		)
	}
}

export default ProductSeo

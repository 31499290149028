import React from 'react'
import { Redirect } from 'react-router-dom'

import ViewHelper from 'ko_core/helpers/view.helper'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import ContentTopSection from 'ko_core/components/organisms/ContentTopSection/ContentTopSection'

class PageNotFound extends React.Component {
	constructor(props) {
		super(props)

		this.isLoggedIn = props.sessionData.user.id != null
		this.clientLogo = props.sessionData.configuration.logo_image || ''
	}

	render() {
		if (!this.isLoggedIn) {
			return <Redirect to='/login' />
		}

		let p = {...this.props}
		p.viewConfig = {
			view: {
				navigationParentId: '',
			}
		}

		let header = ViewHelper.getHeaderComponent(p)
		let nav = ViewHelper.getNavigationComponent(p)
		return (
			<React.Fragment>
				{header}
				<div className="template-content">
					{nav}
					<div className="content">
						<ContentTopSection
							title='ERROR'
							breadcrumbs={[{key: 0, content: 'ERROR', active: true}]}
							date={null}
							sessionData={this.props.sessionData}
							actionsEnabled={false}
						/>
					</div>
					<Footer />
				</div>
			</React.Fragment>
		)
	}
}

export default PageNotFound

import React from 'react'
import PropTypes from 'prop-types'
import MenuIcon from '../../molecules/MenuIcon/MenuIcon'

const HeaderNoNav = (props) => {
	return (
		<div className="header-container">
			<div className="presentation-section">
				{/* acronym logo */}
				<div className="header-info-container acronym-logo">
					<a href="https://www.acronym.com" target="_blank" >
						<div className="logo" />
					</a>
				</div>
				{/* client logo */}
				{/* <div className="header-info-container client-logo">
					<div className="logo" />
				</div> */}
			</div>

			<div className="control-section">
				{/* Help */}
				<div className="header-info-container icon notifications">
					<a href="mailto:support@keywordobjects.com">
						<MenuIcon name="notifications" active={false}>
							<span>Help</span>
						</MenuIcon>
					</a>
				</div>
			</div>
		</div>
	)
}

HeaderNoNav.propTypes = {}

HeaderNoNav.defaultProps = {}

export default HeaderNoNav

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

var timerid = false

if (timerid) {
	clearTimeout(timerid)
}

timerid = setTimeout(() => {
	const rootElement = document.getElementById('root')
    const sessionData = JSON.parse(htmlDecode(rootElement.getAttribute('data-model')))

	rootElement.classList.add('login')

	ReactDOM.render(<App sessionData={sessionData} />, rootElement)
}, 200)

function htmlDecode(input)
{
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

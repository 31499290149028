import React from 'react'
import { Accordion } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import NavigationSubSection from '../NavigationSubSection/NavigationSubSection'
import NavigationLink from '../NavigationLink/NavigationLink'

import NavigationSectionIcon from '../NavigationSectionIcon/NavigationSectionIcon'

class NavigationSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = { activeIndex: props.sectionOpen, sectionIsActive: props.sectionIsActive }
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.autoScrollDown && prevProps.sectionActive !== this.props.sectionActive) {
			//console.log('Scroll down')
			let navigationMenuContainer = document.getElementById("navigation-menu-container")
			navigationMenuContainer.scrollTop = navigationMenuContainer.scrollHeight
		}
	}

	handleClick = (e, titleProps) => {
		const { index } = titleProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index
		console.log('NavigationSection:handleclick', e, titleProps.id)
		this.setState({ activeIndex: newIndex })
		this.props.sectionOpenClickHandler(titleProps.id, this.props.sectionActive)
	}

	isSubsection(item) {
		//console.log('isSubsection', item)
		return item.links !== null && item.links !== undefined ? true : false
	}

	render() {
		const { activeIndex } = this.state
		let selectedSectionId = this.props.sectionSelectedId
		let selectedLinkId = this.props.sectionLinkSelectedId

		let activeClass = this.props.sectionIsActive === true ? 'active' : ''
		let sectionClass = 'navigation-section-title caption2 noselect ' + activeClass

		//console.log('navigationSection:render:', selectedLinkId)
		return (
			<Accordion className="navigation-section">
				<Accordion.Title
					id={this.props.sectionId}
					className="navigation-section-title-container"
					active={this.props.sectionActive}
					index={0}
					onClick={this.handleClick}
				>
					<div className="section-header">
						<NavigationSectionIcon
							key={this.props.sectionTitle}
							name={this.props.sectionTitle}
							active={this.props.sectionIsActive}
						/>
						<div className={sectionClass}>{this.props.sectionTitle}</div>
					</div>
				</Accordion.Title>
				<Accordion.Content active={this.props.sectionActive}>
					{this.props.sectionLinks.map((sectionItem, index) => {
						//console.log('sectionLinks::', sectionItem)
						var isItemSubsection = this.isSubsection(sectionItem)

						if (isItemSubsection) {
							let selected = sectionItem.id === selectedSectionId ? true : false
							return (
								<NavigationSubSection
									key={sectionItem.label}
									sectionLinks={sectionItem.links}
									sectionTitle={sectionItem.label}
									sectionSelectedId={selectedLinkId}
								/>
							)
						} else {
							let selected = sectionItem.id === selectedLinkId ? true : false
							//console.log('sectionItem', selected, sectionItem.id, selectedLinkId, sectionItem)
							return (
								<NavigationLink
									key={sectionItem.label}
									href={sectionItem.url}
									sectionActive={selected}
								>
									{sectionItem.label}
								</NavigationLink>
							)
						}
					})}
				</Accordion.Content>
			</Accordion>
		)
	}
}

NavigationSection.propTypes = {
	sectionTitle: PropTypes.string,
	sectionLinks: PropTypes.array,
	sectionOpen: PropTypes.number,
	selectedSectionId: PropTypes.string,
	sectionLinkSelectedId: PropTypes.string
}

NavigationSection.defaultProps = {
	sectionTitle: '',
	sectionLinks: [],
	sectionOpen: 0,
	selectedSectionId: '',
	sectionLinkSelectedId: ''
}

export default NavigationSection

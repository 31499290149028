import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import { Form, Button, Input, Container, Divider, Grid, Segment } from 'semantic-ui-react'

class Download extends React.Component {
	constructor(props) {
		super(props)

		if (props.route.match.match.params[1] != null) {
			this.downloadUrl = this.createUrlFromParams(props.route.match.match.params[1])
		}
	}

	createUrlFromParams(route) {
		let app = this.props.sessionData.configuration.app
		let url = app + '/download/' + route
		return url
	}

	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<Grid columns={3} verticalAlign="top" className="download-grid-container" centered>
					<Segment className="download-segment">
						<h1>Keyword Objects File Download</h1>
						<Segment>
							<a href={this.downloadUrl}>
								<div className="download-container">
									<div className="ko-icon icon-download" />
									<div>
										<span>Click Here to Download</span>
									</div>
								</div>
							</a>
						</Segment>
					</Segment>
				</Grid>
				<Footer />
			</React.Fragment>
		)
	}
}

export default Download

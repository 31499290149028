import React from 'react'
import PropTypes from 'prop-types'
import BreadcrumbComponent from 'ko_core/components/atoms/BreadcrumbComponent/BreadcrumbComponent'
import 'react-dates/initialize'
import KODatepickerButton from '../KODatepickerButton/KODatepickerButton'
import { Button } from 'semantic-ui-react'

class ContentTopSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: null,
			endDate: null,
			focusedInput: null,
			activeMenuIdx: 0,
		}
	}

	onExportClick() {
		this.props.exportHandler(true)
	}

	onMenuClick = (idx, path) => {
		this.props.triggerGridApiCall({}, undefined, path);
		this.setState({activeMenuIdx: idx});
	}

	formatForNewTaxonomy = (name) => {
		if (this.isNewTaxonomy(name)) {
			return name.split('~').map(a=>{return a.split(':')}).map(a=>{if (a.length<2) {return ''}return a[1]}).join('_');
		}
		if (this.isNewTaxonomyAlt(name)) {
			return name.split('.').map(a=>{return a.split('_')}).map(a=>{if (a.length<2) {return ''}return a[1]}).join('_');
		}

		return name;
	}

	isNewTaxonomy = (name) => {
		return (name.match(/:/g) || []).length > 3 && (name.match(/~/g) || []).length > 2;
	}

	isNewTaxonomyAlt = (name) => {
		return (name.match(/_/g) || []).length > 3 && (name.match(/\./g) || []).length > 2;
	}

	render() {
		let actions = ''
		let datePicker = ''
		let addNewForm = ''
		let exportElement = ''
		let exportsDisabled = this.props.gridLoadingState;
		let title = this.formatForNewTaxonomy(this.props.title);

		if (this.props.actionsEnabled === true) {
			if (this.props.datePickerAlternative == null) {
				let startDate = this.props.date.startDate
				if (!startDate && 'date_range' in this.props.sessionData.page) {
					startDate = this.props.sessionData.page.date_range.from
				}
				let endDate = this.props.date.endDate
				if (!endDate && 'date_range' in this.props.sessionData.page) {
					endDate = this.props.sessionData.page.date_range.to
				}
				datePicker = (
					<KODatepickerButton
						startDate={startDate}
						endDate={endDate}
						previousDateRangeStartDateDisplay={this.props.date.previousDateRangeStartDateDisplay}
						previousDateRangeEndDateDisplay={this.props.date.previousDateRangeEndDateDisplay}
						usePrevious={this.props.date.usePrevious}
						onDateRangeChange={this.props.onDateRangeChange}
						dateCompareAvailable={this.props.dateCompareAvailable}
					/>
				)
			} else {
				datePicker = this.props.datePickerAlternative.length > 0
					? <Button className="ko-button button-with-icon" disabled>
						<span className="label">{this.props.datePickerAlternative}</span>
					</Button>
					: ''
			}

			if (this.props.export == true) {
				exportElement = (
					<Button className="ko-button button-with-icon export-btn" disabled={exportsDisabled} onClick={this.props.exportHandler}>
						<span className="koicon koicon-export"></span>
						<span className="label">EXPORT</span>
					</Button>
				)
			}

			if (this.props.addNewForm == true) {
				if (this.props.addNewFormConfig.hasFilter) {
					let buttons = this.props.addNewFormConfig.buttons.map((b, idx) => {
						let actv = idx === this.state.activeMenuIdx ? 'active' : '';
						return (
							<a className={`item ${actv}`} key={b.text} onClick={() => this.onMenuClick(idx, b.path)}>
								{b.text}
							</a>
						)
					})
					addNewForm = (
						<div className="ui menu">
							{buttons}
						</div>
					)
				}
				if (this.props.addNewFormConfig.label && this.props.addNewFormConfig.path) {
					addNewForm = (
						<>
							{addNewForm}
							<Button className="ko-button button-with-icon" onClick={() => window.location.href = this.props.addNewFormConfig.path} primary>
								<span className="koicon koicon-addnewform"></span>
								<span className="label">{this.props.addNewFormConfig.label}</span>
							</Button>
						</>
					)
				}
			}

			actions = (
				<div className="page-actions button-group">
					{exportElement}
					{datePicker}
					{addNewForm}
				</div>
			)
		}
		return (
			<div className="content-topsection">
				<BreadcrumbComponent sections={this.props.breadcrumbs} />
				<div className="content-topsection-header">
					<h2 title={title}>{title}</h2>
					{actions}
				</div>
			</div>
		)
	}
}

ContentTopSection.propTypes = {
	title: PropTypes.string,
	breadcrumbs: PropTypes.array,
	date: PropTypes.object,
	actionsEnabled: PropTypes.bool,
	exportHandler: PropTypes.func,
	export: PropTypes.bool,
	sessionData: PropTypes.object,
	onDateRangeChange: PropTypes.func,
	addNewForm: PropTypes.bool,
	addNewFormConfig: PropTypes.object,
	triggerGridApiCall: PropTypes.func,
	gridLoadingState: PropTypes.bool
}

ContentTopSection.defaultProps = {
	title: '',
	breadcrumbs: [],
	date: {},
	actionsEnabled: true,
	exportHandler: () => { },
	export: true,
	sessionData: {},
	onDateRangeChange: () => { },
	addNewForm: false,
	addNewFormConfig: {},
	triggerGridApiCall: () => { },
	gridLoadingState: true
}

export default ContentTopSection

var axios = require('axios')
const queryString = require('query-string')
const qs = require('qs')

/**
 * Endpoint constants
 */
//Scorecard
var urlOverview = 'Overview'
//TrendChart
let trendChartEndPoint = 'Chart'
//TrendChart Index
let trendIndexChartEndPoint = 'IndexChart'
//Treemap
let treeMapChartEndPoint = 'treeMapChart'
//DotPlotChartEndPoint
let DotChartEndPoint = 'Effectiveness'
//List chart
let ListChartEndPoint = 'chart'
//Percent List chart
let PercentListChartEndPoint = 'Share'

//Grid
let indexGridEndPoint = 'IndexGrid'
let indexRawGridEndPoint = 'IndexGridRaw'
let indexGridTotalEndPoint = 'IndexGridTotal'

//flaggedService
let flaggedServiceEndPoint = 'blacklist'

//export index
let exportIndexEndPoint = 'index'

/**
 * Order
 */
var urlBaseGridIndex = 'IndexGrid'
var urlBaseGridIndexRaw = 'IndexGridRaw'

let urlChartBase = appBase + '/ajax/keywords/IndexChart?'
let appBase = 'seo'
//let viewBase = 'ppc/ajax/publishers/'
//let urltBaseChartIndex = viewBase + 'Chart?'

let seoAppBase = 'seo/ajax/'

//treemap
let ppcAppBase = 'ppc/ajax/'

let basePublishers = 'ppc/ajax/publishers/'
let treeMapChartURL = basePublishers + treeMapChartEndPoint

//trendchart
let viewBase = 'seo/ajax/keywords/'
let urltBaseChartIndex = viewBase + 'IndexChart?'
//var urlGrid = 'https://proto.ko.ext/dev.php/seo/ajax/keywords/IndexGrid?query=%7B%22from%22%3A%222017-03-17%22%2C%22to%22%3A%222017-03-30%22%2C%22date_compare%22%3Afalse%2C%22offset%22%3A0%2C%22records%22%3A1000%2C%22page%22%3A1%2C%22service_uid%22%3A%223a470163-5257-fd2d-7ea8-eadd04147bc1%22%2C%22user_id%22%3A30%2C%22client_id%22%3A1%7D';

//profile
var urlProfileBase = '/' + appBase + '/ajax/profiles/update'
var urlProfileNoApp = '/ajax/profiles/update'

//filters
let filtersBase = appBase + '/ajax/filters/'
let setsListUrl = filtersBase + 'setsList'

//admin filtersets
let filtersAdminBase = 'admin/ajax/filterSets/'
let saveUserFiltersetsListUrl = filtersAdminBase + 'saveSeoUserForm'
let deleteUserFiltersetsListUrl = filtersAdminBase + 'SeoFiltersDelete'

/**
 * Filter list
 */
let specialFilterRequest = filtersBase + 'list'

let filterSetsConfig = {
    FILTER_TYPES: { GLOBAL: 1, CLIENT: 2, USER: 3 }
}

let headersConfig = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'en-US,en;q=0.9,es;q=0.8,pt;q=0.7',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
    }
}

/**
 * helpers
 */
function getUIDSection(length = 4) {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

function getUID() {
    return (
        getUIDSection() +
        getUIDSection() +
        '-' +
        getUIDSection() +
        '-' +
        getUIDSection() +
        '-' +
        getUIDSection() +
        '-' +
        getUIDSection() +
        getUIDSection() +
        getUIDSection()
    )
}

/**
 * Main request method
 */
function sendGetQueryService(url, params, sendParams = true) {
    // console.log('sendGetQueryService', url, params)
    let queryUrl = url + ((sendParams) ? '?query=' + encodeURIComponent(params) : '')
    return axios
        .get(queryUrl)
        .then(function(response) {
            //console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            //console.log('service:url::error::', error)
        })
}

function sendPostQueryService(url, params) {
    //console.log('sendPostQueryService', url, params)
    let queryUrl = url
    let queryParams = 'query=' + params
    return axios
        .post(queryUrl, queryParams)
        .then(function(response) {
            //console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            //console.log('service:url::error::', error)
        })
}

function sendPostFormService(url, params) {
    console.log('sendPostFormService', url, params)
    let queryUrl = url
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return axios
        .post(queryUrl, qs.stringify(params), config)
        .then(function(response) {
            console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            console.log('service:url::error::', error)
        })
}

// Portal does not support PUT method :(
function sendPutFormService(url, params) {
    console.log('sendPutFormService', url, params)
    let queryUrl = url
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return axios
        .put(queryUrl, qs.stringify(params), config)
        .then(function(response) {
            console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            console.log('service:url::error::', error)
        })
}

function sendPostFormTextService(url, params) {
    console.log('sendPostFormService', url, params)
    let queryUrl = url
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    let qsParams = qs.stringify(params)
    return axios
        .post(queryUrl, qs.stringify(params), config)
        .then(function(response) {
            console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            console.log('service:url::error::', error)
        })
}

function fetchTrendChartData(params, servicePath, app, urlOverride) {
    let endPoint = urlOverride || getTrendChartUrl(trendChartEndPoint, app, servicePath)
    if (params.date_compare) {
        return fetchTrendChartDataWithPrevious(params, endPoint)
    }
    return sendGetQueryService(endPoint, getParamsToJSON(getTrendChartParams(params)))
}

function fetchTrendChartDataWithPrevious(params, endPoint) {
    let payload = {}
    params = getTrendChartParams(params)
    return new Promise(function(resolve, reject) {
        sendGetQueryService(endPoint, getParamsToJSON(params)).then(data => {
            payload = data
            params.historical_only = true
            return sendGetQueryService(endPoint, getParamsToJSON(params)).then(data => {
                payload.data.historicals = data.data
                resolve(payload)
            })
        })
    })
}

function getTrendChartUrl(endpoint, app, servicePath) {
    console.log('getTrendChartUrl', endpoint, app, servicePath)
    let url = `${servicePath}${endpoint}`
    return url
}

function getExportUrl(endpoint, servicePath) {
    console.log('getExportUrl', endpoint, servicePath)
    let url = `${servicePath}${endpoint}`
    return url
}

function getTrendChartParams(params) {
    var paramsBase = {
        from: params.from || '2017-01-17',
        to: params.to || '2017-03-30',
        date_compare: params.date_compare || false,
        historical_from: params.historical_from || null,
        historical_to: params.historical_to || null,
        aggregation: params.aggregation || 'daily',
        user_id: params.userID || '',
        client_id: params.clientID || '',
        service_uid: getUID()
    }

    if (params.id != null) {
        paramsBase.id = params.id
    }

    if (params.max_points != null) {
        paramsBase.max_points = params.max_points
    }

    return paramsBase
}

/**
 * Charts clusters
 */
function fetchChartClusters(params, servicePath, endPointPath) {
    let endPoint = getUrlFromPathEndpoint(servicePath, endPointPath)
    return sendGetQueryService(endPoint, getParamsToJSON(getChartClustersParams(params)))
}

function getUrlFromPathEndpoint(servicePath, endpoint) {
    let url = `${servicePath}${endpoint}`
    return url
}

function getChartClustersParams(params) {
    var paramsBase = {
        user_id: params.userID || '',
        client_id: params.clientID || '',
        service_uid: params.service_uid || '',
        level: params.level || ''
    }

    return paramsBase
}

/**
 * Treemap service functions
 */

function getTreemapParams(params) {
    let treemapParams = {
        metric: params.metric,
        dimension: params.dimension,
        user_id: params.userID,
        client_id: params.clientID,
        from: params.from,
        to: params.to,
        service_uid: getUID()
    }

    if (params.segment) {
        treemapParams.segment = params.segment
    }

    return treemapParams
}

function getTreemMapUrl(dimension) {
    let url
    url = `${ppcAppBase}${dimension}s/${treeMapChartEndPoint}`
    return url
}

function getTreemapService(params, urlOverride) {
    let endPoint = ''
    if (urlOverride != null && urlOverride != '') {
        endPoint = urlOverride
    } else {
        endPoint = getTreemMapUrl(params.dimension)
    }
    return sendGetQueryService(endPoint, getParamsToJSON(getTreemapParams(params)))
}

/**
 * Dot plot service call
 */

function fetchDotPlotChartData(params, servicePath, app, urlOverride) {
    let endPoint = urlOverride || getDotPlotChartUrl(DotChartEndPoint, app, servicePath)
    return sendGetQueryService(endPoint, getParamsToJSON(getDotChartParams(params)))
}

function getDotPlotChartUrl(endpoint, app, servicePath) {
    console.log('getTrendChartUrl', endpoint, app, servicePath)
    let url = `${servicePath}${endpoint}`
    return url
}

function getDotChartParams(params) {
    let dotplotParams = {
        user_id: params.userID,
        client_id: params.clientID,
        from: params.from || '2017-03-22',
        to: params.to || '2017-03-28',
        service_uid: getUID(),
    }

    if (params.id != null) {
        dotplotParams.id = params.id
    }

    return dotplotParams
}

/**
 * list chart service call
 */
function fetchListChartData(params, servicePath, app, urlOverride) {
    let endPoint = urlOverride || getListChartUrl(ListChartEndPoint, app, servicePath)
    return sendGetQueryService(endPoint, getParamsToJSON(getListChartParams(params)))
}

function getListChartUrl(endpoint, app, servicePath) {
    console.log('getListChartUrl', endpoint, app, servicePath)
    let url = `${servicePath}${endpoint}`
    return url
}

function getListChartParams(params) {
    let listParams = {
        user_id: params.userID,
        client_id: params.clientID,
        from: params.from || '2017-03-22',
        to: params.to || '2017-03-28',
        service_uid: getUID()
    }

    if (params.aggregation != null) {
        listParams.aggregation = params.aggregation
    }

    return listParams
}

/**
 * percent list chart service call
 */
function fetchPercentListChartData(params, servicePath, app, urlOverride) {
    let endPoint = urlOverride || getPercentListChartUrl(PercentListChartEndPoint, app, servicePath)
    return sendGetQueryService(endPoint, getParamsToJSON(getPercentListChartParams(params)))
}

function getPercentListChartUrl(endpoint, app, servicePath) {
    console.log('getPercentListChartUrl', endpoint, app, servicePath)
    let url = `${servicePath}${endpoint}`
    return url
}

function getPercentListChartParams(params) {
    let percentlistParams = {
        user_id: params.userID,
        client_id: params.clientID,
        from: params.from || '2017-03-22',
        to: params.to || '2017-03-28',
        service_uid: getUID()
    }

    if (params.aggregation != null) {
        percentlistParams.aggregation = params.aggregation
    }

    if (params.segment != null) {
        percentlistParams.segment = params.segment
    }

    return percentlistParams
}

/**
 * Grid
 */
function fetchGridData(params, servicePath, app, urlOverride, useRaw = false) {
    let endPoint = ''

    if (urlOverride) {
        if (useRaw === true) {
            endPoint = urlOverride + 'Raw'
        } else {
            endPoint = urlOverride
        }
    } else {
        /*****************
         * DEVELOPER'S NOTE
         * The following 3 lines were added with the app limited to dash. This is a method which is used everywhere, and it is 
         * very bad. There is no way to pass in a servicePath and have it be used. This method is in serious need of being redone.
         *****************/
        if (app === 'dash') {
            endPoint = servicePath;
        } else {
            if (useRaw === true) {
                endPoint = getGridUrl(indexGridEndPoint + 'Raw', app, servicePath)
            } else {
                endPoint = getGridUrl(indexGridEndPoint, app, servicePath)
            }
        }
    }

    return sendGetQueryService(endPoint, getParamsToJSON(params))
}

function getGridUrl(endpoint, app, servicePath) {
    let url = `${servicePath}${endpoint}`
    return url
}

function fetchGridTotalData(params, servicePath, app, urlOverride, useRaw = false) {
    // let serviceArgs = Array.from(arguments)
    //console.table(serviceArgs)
    let endPoint = ''

    if (urlOverride) {
        endPoint = urlOverride
    } else {
        endPoint = getGridUrl(indexGridTotalEndPoint, app, servicePath)
    }

    return sendGetQueryService(endPoint, getParamsToJSON(params))
}

function fetchFileExport(params, exportPath) {
    return sendPostQueryService(exportPath, encodeURIComponent(getParamsToJSON(params)))
}

function fetchEmailExport(params, exportPath) {
    return sendGetQueryService(exportPath, getParamsToJSON(params))
}

function downloadFile(response, fileName = 'unknown') {
    const blob = new Blob([response.data], {type: response.data.type});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
        fileName = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
    }

    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }
}

function triggerFileDownload(url) {
    return axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
    })
}

function triggerExportDownload(url, params = {}) {
    return axios({
        url: url,
        params: params,
        method: 'GET',
        responseType: 'blob'
    })
}

function setViewBase(base) {
    viewBase = base
}

function getService(url, config) {
    console.log(url, config)
    return axios
        .get(url, config)
        .then(function(response) {
            //console.log('getChartResponse::', response)
            return response
        })
        .catch(function(error) {
            console.log(error)
        })
}

function getProfileService(url, params) {
    console.log('getProfileService::', url, params)

    return axios
        .get(url, params)
        .then(function(response) {
            //console.log('getChartResponse::', response)
            return response
        })
        .catch(function(error) {
            console.log(error)
        })
}

function getUpdateProfileParams(params) {
    let profileParams = {
        type: params.type,
        ids: JSON.stringify(params.ids),
        user_id: params.user_id,
        client_id: params.client_id
    }

    let paramsWrapper = {}
    paramsWrapper.params = profileParams
    paramsWrapper.paramsSerializer = function(params) {
        return qs.stringify(params)
    }

    return paramsWrapper
}

function getQueryWrappedJSON(params) {
    return jsonToParamsWrapper(getParamsToJSON(params))
}

function jsonToParamsWrapper(params) {
    let paramsWrapper = {}
    paramsWrapper.params = params
    paramsWrapper.paramsSerializer = function(params) {
        return qs.stringify(params)
    }

    return paramsWrapper
}

function getParamsToJSON(params) {
    return JSON.stringify(params)
}

function sendGetService(url, params) {
    return axios
        .get(url, params)
        .then(function(response) {
            console.log('service:url::200::', url, params, response)
            return response
        })
        .catch(function(error) {
            console.log('service:url::error::', error)
        })
}

function getSetListUrl(appParam) {
    let app = appParam || appBase

    // hopefully temporary hack
    if (app === 'synergy') {
        app = 'syn'
    }

    let filtersBase = app + '/ajax/filters/'
    let setsListUrl = filtersBase + 'setsList'
    return setsListUrl
}

function getFiltsetSetLists(params, app) {
    return sendGetQueryService(
        getSetListUrl(app),
        getParamsToJSON(
            getFiltsetSetListsParams(params.userID, params.domainID, params.grid, params.clientID)
        )
    )
}

function getFiltsetSetListsParams(user_id, domain_id, grid, client_id) {
    let params = {
        user_id,
        domain_id,
        grid,
        client_id
    }

    return params
}

function saveUserFilterSetLists(params, app) {
    saveUserFiltersetsListUrl = filtersAdminBase + 'save' + app.charAt(0).toUpperCase() + app.slice(1) + 'UserForm'
    return sendGetQueryService(
        saveUserFiltersetsListUrl,
        getParamsToJSON(
            saveUserFilterSetListsParams(
                params, 
                app
            )
        )
    )
}

function saveUserFilterSetListsParams(parameters, app) {
    let appUCase = app.charAt(0).toUpperCase() + app.slice(1)
    let params = {
        domain_id: parameters.domain_id,
        user_id: parameters.user_id, 
        client_id: parameters.client_id
    }
    params['Filters' + appUCase] = {
        id: parameters.id || null,
        name: parameters.name,
        filters: parameters.filters,
        foreign_id: parameters.foreign_id,
    }
    params['Filters' + appUCase][app + '_grid_id'] = parameters.grid_id
    params['Filters' + appUCase][app + '_filter_type_id'] = filterSetsConfig.FILTER_TYPES[parameters.filter_type_id]
    return params
}

function deleteUserFilterSetLists(params, app) {
    deleteUserFiltersetsListUrl = filtersAdminBase + app.charAt(0).toUpperCase() + app.slice(1) + 'FiltersDelete'
    return sendGetQueryService(deleteUserFiltersetsListUrl, getParamsToJSON(params))
}

function getUrl(servicePath, endPoint, params, useQueryParams) {
    return `${servicePath}${endPoint}`
}

function getUrlWithQueryParams(servicePath, endPoint, params) {
    return `${servicePath}${endPoint}?query=${params}`
}

function getFilterSpecialParams(params) {
    let baseParams = {
        type: params.type,
        user_id: params.user_id,
        client_id: params.client_id
    }

    return baseParams
}

function getSpecialFilterList(app, params) {
    let listUrl = app + '/ajax/filters/list'
    return sendGetQueryService(listUrl, getParamsToJSON(getFilterSpecialParams(params)))
}

function getBaseParams(params) {
    let baseParams = {
        user_id: params.userID,
        client_id: params.clientID
    }

    return baseParams
}

function getScorecardParams(params) {
    let baseParams = {
        from: params.from || '2019-01-17',
        to: params.to || '2017-03-30',
        user_id: params.userID || '',
        client_id: params.clientID || '',
        date_compare: params.date_compare || false,
        historical_from: params.historical_from || null,
        historical_to: params.historical_to || null,
        service_uid: getUID(),
        id: params.id
    }

    if ('updateSession' in params) {
        baseParams.updateSession = params.updateSession
    }    

    return baseParams
}

function getScorecardUrl(endpoint, app, servicePath) {
    let url = `${servicePath}${endpoint}`
    return url
}

function fetchScorecardData(params, servicePath, app) {
    return sendGetQueryService(
        getScorecardUrl(urlOverview, app, servicePath),
        getParamsToJSON(getScorecardParams(params))
    )
}

/**
 * manage blacklist
 */
function getFlaggedServiceUrl(endpoint, servicePath) {
    return 'seo/ajax/go/blacklist'
}

function getFlaggedServiceParams(params) {
    var paramsBase = {
        // from: params.from || '2017-01-17',
        // to: params.to || '2017-03-30',
        wordpart: params.wordpart || [],
        remove: params.remove || 0,
        user_id: params.userID || '',
        client_id: params.clientID || '',
        service_uid: getUID()
    }

    return paramsBase
}

function setFlaggedService(params, servicePath) {
    return sendPostFormService(
        getFlaggedServiceUrl(flaggedServiceEndPoint, servicePath),
        getFlaggedServiceParams(params)
    )
    // return sendPostQueryService(
    //  getFlaggedServiceUrl(flaggedServiceEndPoint, servicePath),
    //  getFlaggedServiceParams(params)
    // )
}

function oldfetchChartData(params, urlOverride) {
    let paramsBase = {
        from: '2017-01-17',
        to: '2017-03-30',
        date_compare: false,
        aggregation: 'daily',
        service_uid: getUID(),
        user_id: 30,
        client_id: 1
    }
    //console.log('api/fetchChartData:')
    if (params.aggregation) {
        paramsBase.aggregation = params.aggregation
    }
    var URI = urltBaseChartIndex + queryString.stringify(paramsBase)
    if (urlOverride != null && urlOverride != undefined && urlOverride != '') {
        URI = urlOverride + '?' + queryString.stringify(paramsBase)
        console.log('urlOverride::', URI)
    }

    return getService(URI, headersConfig).then(function(response) {
        //console.log('fetchChartResponse::', response)
        return response
    })
}

function searchEngineList(params) {
    params.service_uid = getUID()

    let url = 'seo/ajax/rankings/rankingEngine'

    return sendGetQueryService(url, getParamsToJSON(params))
}

/* Notification */
function dismissNotification() {
    let endPoint = 'notifications/dismiss/1'
    return sendGetQueryService(endPoint, [], false)
}

module.exports = {
    getUID: getUID,
    fetchScorecardData: fetchScorecardData,
    fetchTrendChartData: fetchTrendChartData,
    fetchGridData: fetchGridData,
    fetchChartClusters: fetchChartClusters,
    fetchDotPlotChartData: fetchDotPlotChartData,
    fetchListChartData: fetchListChartData,
    fetchPercentListChartData: fetchPercentListChartData,
    fetchGridTotalData: fetchGridTotalData,
    fetchFileExport: fetchFileExport,
    fetchEmailExport: fetchEmailExport,
    triggerFileDownload: triggerFileDownload,
    triggerExportDownload: triggerExportDownload,
    downloadFile: downloadFile,
    setFlaggedService: setFlaggedService,
    setBlacklistService: setFlaggedService,

    updateProfile: function(params, config) {
        let profileParams = getUpdateProfileParams(params)
        let app = config.app;
        let controller = config.controller + '.php'
        let URI = '/' + controller + '/' + app + urlProfileNoApp
        //let URI = urlProfileBase + JSON.stringify(profileParams);

        return getProfileService(URI, profileParams).then(function(response) {
            console.log('profileSaved::', response)
            //return response.data;
        })
    },

    getFilterSetLists: getFiltsetSetLists,

    saveUserFilterSetLists: saveUserFilterSetLists,
    deleteUserFilterSetLists: deleteUserFilterSetLists,

    //expose?
    getQueryWrappedJSON: getQueryWrappedJSON,
    sendGetService: sendGetService,

    getSpecialFilterList: getSpecialFilterList,
    getTreemapService: getTreemapService,

    //base
    sendGetQueryService: sendGetQueryService,
    sendPostQueryService: sendPostQueryService,
    sendPostFormService: sendPostFormService,
    sendPutFormService: sendPutFormService,
    sendPostFormTextService: sendPostFormTextService,
    getParamsToJSON: getParamsToJSON,
    getBaseParams: getBaseParams,
    searchEngineList: searchEngineList,

    dismissNotification: dismissNotification
}

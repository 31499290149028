function getAppConfig(componentList, appRoutes, mainApp) {
	let config = {}
	config.componentList = componentList
	config.appRoutes = addFieldToRoutes(appRoutes, 'mainapp', mainApp)
	config.appRoutes = addRoutesForAbsentFEController(config.appRoutes)

	return config
}

function addFieldToRoutes(config, field, value) {
	for (let i = 0; i < config.length; i++) {
		config[i][field] = value
	}

	return config
}

function addRoutesForAbsentFEController(routes) {
	let r = []
	let obj = {}
	let objCopy = {}
	let objDevCopy = {}

	for (let i = 0; i < routes.length; i++) {
		obj = routes[i]
		r.push(obj)
		objCopy = JSON.parse(JSON.stringify(obj))
		objCopy.fullpath = 'index.php/' + obj.fullpath
		r.push(objCopy)
		objDevCopy = JSON.parse(JSON.stringify(obj))
		objDevCopy.fullpath = 'dev.php/' + obj.fullpath
		r.push(objDevCopy)
	}

	return r
}

function getAllAppsConfig(apps) {
	let appsConfig = {}
	appsConfig.routes = []
	appsConfig.componentList = {}

	for (let i = 0; i < apps.length; i++) {
		const app = apps[i]
		let appConfig = app.getAppConfig()
		appsConfig.componentList = Object.assign(appsConfig.componentList, appConfig.componentList)
		appsConfig.routes = appsConfig.routes.concat(appConfig.appRoutes)
	}

	return appsConfig
}

export default {
	getAllAppsConfig: getAllAppsConfig,
	getAppConfig: getAppConfig,
	addFieldToRoutes: addFieldToRoutes
}

import React from 'react'
import PropTypes from 'prop-types'
import SimpleLink from '../../atoms/SimpleLink/Simplelink'

const NavigationLink = (props) => {
	const linkType = props.type || ''
	const active = props.sectionActive || false
	const activeClass = (active === true) ? 'active' : ''
	const linkClass = `navigation-link noselect ${linkType} ${activeClass}`

	return (
		<div>
			<SimpleLink className={linkClass} href={props.href}>{props.children}</SimpleLink>
		</div>
	)
}

NavigationLink.propTypes = {
	href: PropTypes.string,
	active: PropTypes.bool
}

NavigationLink.defaultProps = {
	href: '',
	active: false
}

export default NavigationLink

import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'

import { Form, Button, Input, Container, Divider, Grid } from 'semantic-ui-react'
import { NonavApi } from '../../../services/NonavApi'

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			text: 'Please enter your email:',
			email: null,
			resetPasswordMessage: null,
			loading: false
		}

		this.clientLogo = props.sessionData.configuration.logo_image || ''
		this.onInputNameChange = this.onInputNameChange.bind(this)
		this.getResetPassword = this.getResetPassword.bind(this)
	}

	onInputNameChange(event, element) {
		let email = element.value
		console.log('onInputNameChange', element, event)
		this.setState({ email })
	}

	getResetPassword() {
		if (!this.state.email) {
			return;
		}

		this.setState({ loading: true })
		let params = {
			text: this.state.email, // ovaldez || ovaldez@acronym.com
			text_type: 'email' // username || email
		}
		NonavApi.getForgotPassService(params).then((data) => {
			console.log('getResetPassword::response::data', data)
			if (data.data.flash) {

				this.setState({ resetPasswordMessage: data.data.flash, text: null, loading: false })
			}
		})
	}

	render() {
		let renderMessage
		if (this.state.resetPasswordMessage != null) {
			renderMessage = (
				<React.Fragment>
					<div>
						<span>{this.state.resetPasswordMessage}</span>
					</div>
					<div className="forgot-pass-container">
						<a className="forgot-pass-link" href="login">
							Back to login
						</a>
					</div>
				</React.Fragment>
			)
		} else {
			renderMessage = (
				<React.Fragment>
					<Grid.Row>
						<div className="inputs-container">
							<Grid>
								<Grid.Column>
									<div className="">
										<Form.Field>
											<Input placeholder="" onChange={this.onInputNameChange} fluid />
										</Form.Field>
									</div>
								</Grid.Column>
							</Grid>
						</div>
					</Grid.Row>
					<Grid.Row className="submit-container">
						<Grid.Column>
							<a className="forgot-pass-link" href="login">
								Back to login
							</a>
							<Button loading={this.state.loading} onClick={this.getResetPassword}>Reset Password</Button>
						</Grid.Column>
					</Grid.Row>
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				<HeaderNoNav />
				<Grid centered columns={3} verticalAlign="middle" className="login-container">
					<Grid.Column verticalAlign="middle" className="login-box">
						<div className="nonav-template-content">
							<div className="content">
								<Container>
									<div className="login">
										<div className="info">
											<div className="logo-container">
												<div className="client-logo">
													<img class="logo" src={this.clientLogo} border="0" />
												</div>
											</div>
											<div className="description-container">
												<span className="description">
													<b>Reset Password</b>
													<br />
													{this.state.text}
												</span>
											</div>
										</div>
										<Divider />
										<div className="form-container">{renderMessage}</div>
									</div>
								</Container>
							</div>
						</div>
					</Grid.Column>
				</Grid>
				<Footer />
			</React.Fragment>
		)
	}
}

export default ForgotPassword

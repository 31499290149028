import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup, Checkbox, Divider, Input, Icon } from 'semantic-ui-react'

import KODatepicker from '../KODatepicker/KODatepicker'
import ReactDatesDatepicker from '../ReactDatesDatepicker/ReactDatesDatepicker'

const moment = require('moment')

class KODatepickerButton extends React.Component {
	constructor(props) {
		super(props)

		this.datesObj = {}

		this.state = this.getDefaultState()

		this.getDefaultState = this.getDefaultState.bind(this)
		this.openDatepicker = this.openDatepicker.bind(this)
		this.toggleDatepickerPopup = this.toggleDatepickerPopup.bind(this)
		this.onDatepickerApply = this.onDatepickerApply.bind(this)
		this.onDatepickerCancel = this.onDatepickerCancel.bind(this)
		this.reopen = this.reopen.bind(this)
	}

	getDefaultState() {
		return {
			datepickerPopupOpen: false,
			startDate: moment(this.props.startDate),
			endDate: moment(this.props.endDate),
			dateRangeStartDateDisplay: '',
			dateRangeEndDateDisplay: '',
			currentlySelectingCompareTo: false,
			previousDateRangeStartDateDisplay: this.props.previousDateRangeStartDateDisplay,
			previousDateRangeEndDateDisplay: this.props.previousDateRangeEndDateDisplay,
			usePrevious: this.props.usePrevious,
		}
	}

	openDatepicker() {
		// Scroll to date picker button for the small screen
		let scrollToDatePicker = (window.innerHeight < 700)
		if (scrollToDatePicker) {
			window.scrollTo(0, this.refs.datePickerButton.ref.offsetTop - 12)
		}

		this.toggleDatepickerPopup()
	}

	reopen(dates, toggle = true) {
		let cb = toggle ? this.toggleDatepickerPopup : () => {}
		this.datesObj.dateRangeType = dates.dateRangeType
		this.datesObj.previousDateRangeType = dates.previousDateRangeType
		this.setState({
			datepickerPopupOpen: !toggle,
			startDate: moment(dates.startDate),
			endDate: moment(dates.endDate),
			dateRangeStartDateDisplay: dates.dateRangeStartDateDisplay,
			dateRangeEndDateDisplay: dates.dateRangeEndDateDisplay,
			previousDateRangeStartDateDisplay: dates.previousDateRangeStartDateDisplay,
			previousDateRangeEndDateDisplay: dates.previousDateRangeEndDateDisplay,
			usePrevious: dates.usePrevious,
			currentlySelectingCompareTo: dates.currentlySelectingCompareTo,
		}, () => {cb()})
	}

	toggleDatepickerPopup(openState = null) {
		openState = openState === null ? !this.state.datepickerPopupOpen : openState
		this.setState({
			datepickerPopupOpen: openState
		})
	}

	onDatepickerCancel(dates) {
		this.setState(this.getDefaultState())
		this.toggleDatepickerPopup(false)
	}

	onDatepickerApply(dates) {
		this.setState({
			startDate: dates.startDate,
			endDate: dates.endDate,
			previousDateRangeStartDateDisplay: dates.previousDateRangeStartDateDisplay,
			previousDateRangeEndDateDisplay: dates.previousDateRangeEndDateDisplay,
			usePrevious: dates.usePrevious,
			currentlySelectingCompareTo: false,
		})

		this.datesObj = dates

		this.props.onDateRangeChange(dates)

		this.toggleDatepickerPopup()
	}

	displayDate(str) {
		let momentObject = typeof(str) === 'string' ? moment(str) : str
		return momentObject ? momentObject.format('MMM D, YYYY') : ''
	}

	render() {
		let startDateString = this.displayDate(this.props.startDate)
		let endDateString = this.displayDate(this.props.endDate)

		return (
				<Popup
					trigger={
						<Button
							className="button-with-icon datepicker-btn ko-button"
							active={this.state.datepickerPopupOpen}
							onClick={this.openDatepicker}
							ref="datePickerButton"
						>
							<span className="label">{startDateString} - {endDateString}</span>
							<span className="koicon koicon-date-picker"></span>
						</Button>
					}
					className="date-picker"
					position="bottom right"
					on="click"
					open={this.state.datepickerPopupOpen}
					onClose={this.onDatepickerCancel}
				>
					<KODatepicker
						onDatepickerApply={this.onDatepickerApply}
						onDatepickerCancel={this.onDatepickerCancel}
						startDate={this.state.startDate}
						endDate={this.state.endDate}
						dateRangeStartDateDisplay={this.state.dateRangeStartDateDisplay}
						dateRangeEndDateDisplay={this.state.dateRangeEndDateDisplay}
						previousDateRangeStartDateDisplay={this.state.previousDateRangeStartDateDisplay}
						previousDateRangeEndDateDisplay={this.state.previousDateRangeEndDateDisplay}
						usePrevious={this.state.usePrevious}
						currentlySelectingCompareTo={this.state.currentlySelectingCompareTo}
						dateRangeType={this.datesObj.dateRangeType}
						previousDateRangeType={this.datesObj.previousDateRangeType}
						dateCompareAvailable={this.props.dateCompareAvailable}
						reopen={this.reopen}
					/>
				</Popup>
		)
	}
}

KODatepickerButton.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	previousDateRangeStartDateDisplay: PropTypes.string,
	previousDateRangeEndDateDisplay: PropTypes.string,
	usePrevious: PropTypes.bool,
	onDateRangeChange: PropTypes.func,
	dateCompareAvailable: PropTypes.bool,
}

KODatepickerButton.defaultProps = {
	startDate: '',
	endDate: '',
	previousDateRangeStartDateDisplay: '',
	previousDateRangeEndDateDisplay: '',
	usePrevious: false,
	onDateRangeChange: () => {},
	dateCompareAvailable: true,
}

export default KODatepickerButton

import React from 'react'
import PropTypes from 'prop-types'

const SimpleLink = (props) => {
	return (
		<a className={props.className} href={props.href} target={props.target} onClick={props.onClick}>
			{props.children}
		</a>
	)
}

SimpleLink.propTypes = {
	href: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func
}

SimpleLink.defaultProps = {
	href: '',
	target: null,
	onClick: () => {}
}

export default SimpleLink

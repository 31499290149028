import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import MenuIcon from '../../molecules/MenuIcon/MenuIcon'
import SubdomainDropdown from '../../molecules/SubdomainDropdown/SubdomainDropdown'
import { Icon, Popup, Divider, Item, Message } from 'semantic-ui-react'
import SimpleLink from '../../atoms/SimpleLink/Simplelink'
import AccountRep from 'ko_core/components/molecules/AccountRep/AccountRep'
import koApi from 'ko_core/helpers/api'
import _ from 'lodash'

class Header extends React.Component {
	constructor(props) {
		super(props)
		// Hide top nav early for small screen size
		this.ScrollPosHideShow = (window.innerHeight < 700) ? -70 : -150
		this.contextRef = createRef()

		this.toggleAdminPopup = this.toggleAdminPopup.bind(this)
		this.toggleUserProfilePopup = this.toggleUserProfilePopup.bind(this)
		this.closePopups = this.closePopups.bind(this)
		this.handleScroll = this.handleScroll.bind(this)
		this.onOpenAdmin = this.onOpenAdmin.bind(this)
		this.onCloseAdmin = this.onCloseAdmin.bind(this)
		this.onOpenHelp = this.onOpenHelp.bind(this)
		this.onCloseHelp = this.onCloseHelp.bind(this)
		this.onOpenUser = this.onOpenUser.bind(this)
		this.onCloseUser = this.onCloseUser.bind(this)
		this.onOpenSubdomain = this.onOpenSubdomain.bind(this)
		this.onToggleSubdomain = this.onToggleSubdomain.bind(this)
		
		this.state = {
			adminLinksPopupOpen: false,
			userProfilePopupOpen: false,
			helpPopupOpen: false,
			subdomainPopupOpen: false,
			show: true,
			showNotification: true
		}
	}

    componentDidMount() {
        window.addEventListener('keydown', (e) => {
            switch (e.keyCode) {
                case 27: // esc
                    this.closePopups();
                    break;
            }
		});
		window.addEventListener("scroll", this.handleScroll);
    }

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll() {
		if (this.state.showNotification) {
			this.handleDismissNotification()
		}

		let showHeader = document.body.getBoundingClientRect().top > this.ScrollPosHideShow

		this.setState({
		  show: showHeader
		});

		if (!showHeader) {
			this.setState({
				subdomainPopupOpen: false, 
				adminLinksPopupOpen: false, 
				userProfilePopupOpen: false, 
				helpPopupOpen: false
			})
		}
	  }

    closePopups() {
    	this.setState({adminLinksPopupOpen: false, userProfilePopupOpen: false, helpPopupOpen: false});
    }

	getAdminPopupContainer() {
		return (
			<div>
				<SimpleLink href="admin/users" onClick={this.toggleAdminPopup}>
					Users
				</SimpleLink>
				<SimpleLink href="admin/synergy/filter-sets" onClick={this.toggleAdminPopup}>
					Synergy Saved Filters
				</SimpleLink>
				<Divider />
				<SimpleLink href="admin/ppc/initiatives" onClick={this.toggleAdminPopup}>
					PPC Initiatives
				</SimpleLink>
				<SimpleLink href="admin/ppc/filter-sets" onClick={this.toggleAdminPopup}>
					PPC Saved Filters
				</SimpleLink>
				<Divider />
				<SimpleLink href="admin/seo/url-groups" onClick={this.toggleAdminPopup}>
					SEO URL Groups
				</SimpleLink>
				<SimpleLink href="admin/seo/url-parent-groups" onClick={this.toggleAdminPopup}>
					SEO URL Parent Groups
				</SimpleLink>
				<SimpleLink href="admin/seo/keyword-groups" onClick={this.toggleAdminPopup}>
					SEO Keyword Groups
				</SimpleLink>
				<SimpleLink href="admin/seo/goals" onClick={this.toggleAdminPopup}>
					SEO Goals
				</SimpleLink>
				<SimpleLink href="admin/seo/filter-sets" onClick={this.toggleAdminPopup}>
					SEO Saved Filters
				</SimpleLink>
			</div>
		)
	}

	toggleAdminPopup() {
		this.setState({
			adminLinksPopupOpen: !this.state.adminLinksPopupOpen,
			userProfilePopupOpen: false,
			helpPopupOpen: false,
		})
	}

	getUserProfilePopupContainer() {
		return (
			<div className="user-profile-popup-container">
				<Item.Group>
					<Item>
						<Item.Content>
							<div className="user-initial-image">{this.props.profileInfo.name.charAt(0)}</div>
							<div className="user-info">
								<div className="user-full-name">{this.props.profileInfo.name}</div>
								<div className="user-email">{this.props.profileInfo.email}</div>
							</div>
						</Item.Content>
					</Item>
				</Item.Group>
				<Divider />
				<SimpleLink href="update-password" onClick={this.toggleUserProfilePopup}>
					Change Password
				</SimpleLink>
				<SimpleLink href="logout" onClick={this.toggleUserProfilePopup}>
					Sign Out
				</SimpleLink>
			</div>
		)
	}

	toggleUserProfilePopup() {
		this.setState({
			userProfilePopupOpen: !this.state.userProfilePopupOpen,
			adminLinksPopupOpen: false,
			helpPopupOpen: false,
		})
	}

	getHelpPopupContainer() {
		let emailSupport = this.props.emailSupport

		return (
			<div className="help-popup-container">
				{ /* @todo: update to config base, will need to do this in various places */ }
				{/* <SimpleLink href={`mailto:${emailSupport}`}> */}
				<SimpleLink href="mailto:support@keywordobjects.com">
					Get in touch with KO Support
				</SimpleLink>
				<Divider />
				<AccountRep accountRep={this.props.accountRep} />
			</div>
		)
	}

	onOpenUser() {
		this.setState({userProfilePopupOpen: true, helpPopupOpen: false, adminLinksPopupOpen: false, subdomainPopupOpen: false})
	}
	onCloseUser() {
		this.setState({userProfilePopupOpen: false})
	}
	onOpenAdmin() {
		this.setState({adminLinksPopupOpen: true, userProfilePopupOpen: false, helpPopupOpen: false, subdomainPopupOpen: false})
	}
	onCloseAdmin() {
		this.setState({adminLinksPopupOpen: false})
	}
	onOpenSubdomain() {
		this.setState({subdomainPopupOpen: true, adminLinksPopupOpen: false, userProfilePopupOpen: false, helpPopupOpen: false})
	}
	onToggleSubdomain() {
		if (this.state.subdomainPopupOpen) {
			return this.setState({subdomainPopupOpen: false})
		} else {
			return this.onOpenSubdomain()
		}
	}
	onOpenHelp() {
		this.setState({helpPopupOpen: true, userProfilePopupOpen: false, adminLinksPopupOpen: false, subdomainPopupOpen: false})
	}
	onCloseHelp() {
		this.setState({helpPopupOpen: false})
	}

	handleDismissNotification = () => {
		// Hide the notification first, since the ajax will take a second, this will give the snappy feeling to the user
		this.setState({ showNotification: false })
		
		koApi.dismissNotification().then((data) => {
			if (data.status === 200 && data.data.result === 'success') {
				
			} else {
				console.log('Dismiss notification failed!!')
			}
		})
	}

	render() {
		let subdomainDropdownInstance = null
		if (
			this.props.clientSubdomains.selected !== '' &&
			this.props.clientSubdomains.subdomains.length > 0
		) {
			subdomainDropdownInstance = (
				<SubdomainDropdown
					open={this.state.subdomainPopupOpen}
					onClick={this.onToggleSubdomain}
					onClose={this.onToggleSubdomain}
					onOpen={this.onOpenSubdomain}
					selected={this.props.clientSubdomains.selected}
					subdomains={this.props.clientSubdomains.subdomains}
				/>
			)
		}

		let adminPopupContainer = this.getAdminPopupContainer()
		let userProfilePopupContainer = this.getUserProfilePopupContainer()
		let helpPopupContainer = this.getHelpPopupContainer()
		let adminPopupDiv = this.props.isAdmin ? (
				<div className="header-info-container icon admin">
					<Popup
						trigger={
							<MenuIcon
								name="admin"
								active={this.state.adminLinksPopupOpen}
							>
								<span>Admin</span>
							</MenuIcon>
						}
						className="admin-links-container"
						position="bottom right"
						on="click"
						open={this.state.adminLinksPopupOpen}
						onOpen={this.onOpenAdmin}
						onClose={this.onCloseAdmin}
					>
						{adminPopupContainer}
					</Popup>
				</div>
			) : ''

		// Check if ML has the message and if it has not yet been read
		// showNotification will be either false or notification string
		let showNotification = _.has(this.props.notifications, 'messages[0].text') && 
			_.has(this.props.notifications, 'messages[0].read') && 
			!this.props.notifications.messages[0].read &&
			this.props.notifications.messages[0].text

		return (
			<React.Fragment>
				{(showNotification
				) ? (
					<Message icon className={`notification ${this.state.showNotification ? 'active' : 'hidden'}`} onDismiss={this.handleDismissNotification}>
						<Icon name='warning sign' />
						{/*<Message.Header>Notification:</Message.Header>*/}
						{/* Since the message is self is in HTML form, need to use below syntax :D */}
						<div dangerouslySetInnerHTML={{ __html: showNotification }} />
					</Message>
					) : ''
				}
				<div className={`header-container ${this.state.show ? 'active' : 'hidden'}`} >
					<div className="presentation-section">
						{/* acronym logo */}
						<div className="header-info-container acronym-logo">
							<a href="https://www.acronym.com" target="_blank">
								<div className="logo" />
							</a>
						</div>
						{/* client logo */}
						<div className="header-info-container client-logo">
							<img className="logo" src={this.props.logoImage} />
						</div>
						{/* client links */}
						<div className="header-info-container subdomains">{subdomainDropdownInstance}</div>
					</div>

					<div className="control-section">
						{ adminPopupDiv }
						{/* Help */}
						<div className="header-info-container icon help">
							<Popup
								trigger={
									<MenuIcon
										name="notifications"
										active={this.state.helpPopupOpen}
									>
										<span>Help</span>
									</MenuIcon>
								}
								wide
								size="small"
								className="admin-links-container"
								position="bottom right"
								on="click"
								open={this.state.helpPopupOpen}
								onOpen={this.onOpenHelp}
								onClose={this.onCloseHelp}
							>
								{helpPopupContainer}
							</Popup>
						</div>
						{/* profile */}
						<div className="header-info-container user">
							<Popup
								trigger={
									<MenuIcon
										name="user"
										active={this.state.userProfilePopupOpen}
									>
										<span className="body-accent">Hello, {this.props.profileInfo.name}</span>
									</MenuIcon>
								}
								className="admin-links-container"
								position="bottom right"
								on="click"
								open={this.state.userProfilePopupOpen}
								onOpen={this.onOpenUser}
								onClose={this.onCloseUser}
							>
								{userProfilePopupContainer}
							</Popup>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

Header.propTypes = {
	clientSubdomains: PropTypes.object,
	isAdmin: PropTypes.bool,
	profileInfo: PropTypes.object,
	logoImage: PropTypes.string,
	emailSupport: PropTypes.string
}

Header.defaultProps = {
	clientSubdomains: {
		selected: '',
		subdomains: []
	},
	isAdmin: false,
	profileInfo: {},
	logoImage: '',
	emailSupport: ''
}

export default Header


import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'semantic-ui-react'

const BreadcrumbComponent = (props) => {
	return (
		<Breadcrumb className='small' icon='right angle' sections={props.sections} />
	)
}

BreadcrumbComponent.propTypes = {
	sections: PropTypes.array
}

BreadcrumbComponent.defaultProps = {
	sections: []
}

export default BreadcrumbComponent

import React from 'react'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import { Form, Button, Container, Divider, Grid } from 'semantic-ui-react'
import axios from 'axios';

class Login extends React.Component {
	constructor(props) {
		super(props)
		let referrer = new URL(window.location.href);
		referrer = referrer.searchParams.get('referrer');
		this.token = props.sessionData.configuration.token || ''
		let environment = props.sessionData.configuration.env || ''
		let entryPoint = environment === 'dev' ? '/dev.php' : ''
		this.formEndpoint = entryPoint + '/login'
		this.clientLogo = props.sessionData.configuration.logo_image || ''
		this.state = {
			username: '',
			password: '',
			error: '',
			redirectUrl: '',
			referrer: referrer,
			authenticated: false,
			loading: false
		  };

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({loading: true})

		//console.log('Submitting login form')
		const username = this.refs.username.value
		const password = this.refs.password.value
		const csrfToken = this.refs.csrfToken.value
		const error = this.validate(username, password);

		//console.log(errors)
		if (error) {
			this.setState({error: error, loading: false})
			return;
		}

		let data = new FormData()
		data.append('user[username]', username);
		data.append('user[password]', password);
		data.append('user[_csrf_token]', csrfToken);
		if (this.state.referrer) {
			data.append('referrer', this.state.referrer);
		}
		let app = this

		axios.post(this.formEndpoint, data)
			.then(function (response) {
				if (response.status === 200) {
					console.log("Login successfull")
					console.log(response.data.redirect_url)
					app.setState({
						redirectUrl: response.data.redirect_url,
						authenticated: true
					})
					// @todo: update to react redirect?
					window.location.replace(response.data.redirect_url);
				}
				else {
					app.setState({
						error:'Invalid username and/or password!', 
						loading: false
					})
					app.refs.password.value = '';
					app.refs.password.focus(); 
				}
			})
			.catch(function (error) {
				//console.log(error);
				app.setState({
					error:'Invalid username and/or password!', 
					loading: false
				})
				app.refs.password.value = '';
				app.refs.password.focus(); 
			});
		// send the form...
	};

	validate(username, password) {
		let error = null;
	  
		if (username.length === 0 || password.length === 0) {
			error = 'Invalid username and/or password!'
		}
	  
		return error;
	  }

	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<Grid centered columns={3} verticalAlign="middle" className="login-container">
					<Grid.Column verticalAlign="middle" className="login-box">
						<div className="nonav-template-content">
							<div className="content">
								<Container>
									<div className="login">
										<div className="info">
											<div className="logo-container">
												<div className="client-logo">
													<img class="logo" src={this.clientLogo} border="0" />
												</div>
											</div>
											<div className="description-container">
												<span className="description">
													<b>Welcome</b>
													<br />A first-of-its-kind search intelligence platform built to aggregate,
													organize and analyze keyword data for what it really is: a rich source of
													unparalleled customer intelligence. What are you waiting for? Log in and
													find out for yourself.
												</span>
											</div>
										</div>
										<Divider />
										{this.state.error.length > 0 &&
											<div className="error">
												{this.state.error}
											</div>
										}
										<Form onSubmit={this.handleSubmit}>
											<Form.Group>
												<input
													type="hidden"
													name="user[_csrf_token]"
													value={this.token}
													ref="csrfToken"
												/>
												<div className="form-container">
													<Grid.Row>
														<div className="inputs-container">
															<Grid centered columns={2}>
																<Grid.Column>
																	<div className="">
																		<Form.Field>
																			<label>Username</label>
																			<input
																				placeholder="Username"
																				name="user[username]"
																				ref="username"
																				autoFocus={true}
																			/>
																		</Form.Field>
																	</div>
																</Grid.Column>
																<Grid.Column>
																	<div className="">
																		<Form.Field>
																			<label>Password</label>
																			<input
																				placeholder="Password"
																				name="user[password]"
																				type="password"
																				ref="password"
																			/>
																		</Form.Field>
																	</div>
																</Grid.Column>
															</Grid>
														</div>
													</Grid.Row>
													<Grid.Row className="submit-container">
														<Grid.Column>
															<a className="forgot-pass-link" href="reset-password">
																Forgot Password
															</a>
															<Button loading={this.state.loading} type="submit">Submit</Button>
														</Grid.Column>
													</Grid.Row>
												</div>
											</Form.Group>
										</Form>
									</div>
								</Container>
							</div>
						</div>
					</Grid.Column>
				</Grid>
				<Footer />
			</React.Fragment>
		)
	}
}

export default Login

import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'

import { Segment } from 'semantic-ui-react'

class ProductPpc extends React.Component {
	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<div className="template-content">
					<div className="content">
						<div className="product-container-area-holder">
							<div className="product-description">
								<h1>ENTERPRISE PPC WITH KEYWORD OBJECTS</h1>
								<div className="main-description">
									<span>
										You use technology to manage your bids, but what about everything else? Are your
										landing pages matched to the respective keywords? Are the right budgets set to
										deliver your specific goals? How do you aggregate all the data from your bid
										management tool, web analytics platform, CRM, call tracking and everything in
										between? Keyword Objects™ (KO) for PPC is built to unite disparate data sources
										across channels into a single, predictive dashboard.
									</span>

									<div className="details">
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Custom Segmentation</div>
												<span>
													KO harnesses a set of dynamic algorithms designed for pattern recognition
													among varied semantic and other keyword objects including keywords,
													landing pages, campaigns, ad groups, publishers, and more.
												</span>
											</div>
										</div>
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Dynamic Reporting by Stakeholder</div>
												<span>
													KO provides unique data clusters to generate dynamic reports using
													similarities in attributes and/or metadata to surface insights and drive
													intelligent customer and intra-keyword segmentation.
												</span>
											</div>
										</div>
										<div className="detail-item">
											<div className="detail-item-circle" />
											<div className="">
												<div className="subtitle">Built for Enterprise Brands</div>
												<span>
													KO allows for customization and depth through custom clusters of data that
													mirror your organizational hierarchy whether that’s based on countries,
													regions, stakeholders, web properties, keywords, campaigns or ad groups.
												</span>
											</div>
										</div>
									</div>
								</div>

								<Segment className="download-segment">
									<a href="https://d1qrtv1cutlyu7.cloudfront.net/ko-enterprise-ppc.pdf">
										<div className="download-container">
											<div className="ko-icon icon-download" />
											<div>
												<span>Download Our Product review</span>
											</div>
										</div>
									</a>
								</Segment>
							</div>
							<div className="product-video-container">
								<h2>Drive better results with search intelligence</h2>
								<div>
									<img
										src="/images/products/PpcDashboard.png"
										width="460"
										height="282"
										border="0"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</React.Fragment>
		)
	}
}

export default ProductPpc

import React from 'react'
import _ from 'lodash'
import { Input, Search, Icon, Button } from 'semantic-ui-react'
import NavigationHelper from 'ko_core/components/atoms/helpers/NavigationHelper/NavigationHelper'

let searchOptionsSource


class NavigationSearch  extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			results: [],
			value: ''
		}
		searchOptionsSource = NavigationHelper.getNavigationSearchResults(props.blacklistedApps, props.blacklistedViews)
	}

	resetComponent = () => this.setState({
		isLoading: false,
		results: [],
		value: ''
	})

	categoryRenderer = ({ name }) => name

	resultRenderer = ({ labels, url }) => {
		return <div>
			<span>
				<a href={url}>
					{labels.slice(0, labels.length - 1).join(' > ')} > 
					<span className="last-label"> {labels[labels.length - 1]}</span>
				</a>
			</span>
		</div>
	}

	noResultRenderer = ({ labels, url }) => {
		return <div className="no-results">{labels[labels.length - 1]}</div>
	}

	onSearchChange = (e, { value }) => {
		this.setState({ isLoading: true, value })
		setTimeout(() => {
			const result = searchOptionsSource.filter(result => result.metadata.toLowerCase().includes(value.toLowerCase()))
			this.setState({
				isLoading: false,
				results: result
			})
		}, 300)
	}

	onSearchDelete = (e) => {
		this.setState({ value: '' })
	}

	onResultSelect = (e, data) => {
		window.location.href = data.result.url
	}

	render() {
		const { isLoading, value, results } = this.state

		let hasResults = (results === undefined || results.length == 0)
			? false : true

		let emptyResults = [{
			labels: ['No matching results'],
			url: '',
			key: 'none',
			title: 'none'

		}]
			
		let resultsWithHeader = {
			'header': {
				'name': 'SEARCH AND JUMP TO:',
				'results': hasResults ? results : emptyResults
			}
		}

		let deleteIcon = (value != '') ? <div className="clickable koicon koicon-clear" onClick={this.onSearchDelete} /> : ''

		return (
			<div className='navigation-search-container'>
				<Search
					category
					categoryRenderer={this.categoryRenderer}
					className={'navigation-search'}
					loading={isLoading}
					onSearchChange={_.debounce(this.onSearchChange, 500, { leading: true })}
					results={resultsWithHeader}
					resultRenderer={hasResults ? this.resultRenderer : this.noResultRenderer}
					value={value}
					input={{ icon: 'search', iconPosition: 'left', placeholder: 'Search for a page', fluid: false, transparent: true }}
					showNoResults={true}
					onResultSelect = {this.onResultSelect}
				/>
				{deleteIcon}
			</div>
		)
	}
}

export default NavigationSearch

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Divider, Input, Icon } from 'semantic-ui-react'
import 'react-dates/initialize'

import { DateRangePicker, DayPickerRangeController } from 'react-dates'
import KODropdown from 'ko_core/components/molecules/KODropdown/KODropdown'
import KOHelpers from 'ko_core/helpers/ko.helpers'
import ReactDatesDatepicker from '../ReactDatesDatepicker/ReactDatesDatepicker'
import { start } from 'pretty-error'

const moment = require('moment')

class KODatepicker extends React.Component {
	constructor(props) {
		super(props)

		this.state = this.getStateFromProps(props)

		this.dateRangeTypeSelected = this.dateRangeTypeSelected.bind(this)
		this.onDateRangeStartDateChange = this.onDateRangeStartDateChange.bind(this)
		this.onDateRangeEndDateChange = this.onDateRangeEndDateChange.bind(this)
		this.previousDateRangeTypeSelected = this.previousDateRangeTypeSelected.bind(this)
		this.selectCompareToPreset = this.selectCompareToPreset.bind(this)
		this.onPreviousDateRangeStartDateChange = this.onPreviousDateRangeStartDateChange.bind(this)
		this.onPreviousDateRangeEndDateChange = this.onPreviousDateRangeEndDateChange.bind(this)
		this.onDatesChangeHandler = this.onDatesChangeHandler.bind(this)
		this.onApply = this.onApply.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onToggleCompare = this.onToggleCompare.bind(this)
		this.changeCurrentlySelecting = this.changeCurrentlySelecting.bind(this)
		this.onDateRangeBlur = this.onDateRangeBlur.bind(this)
		this.onPreviousDateRangeBlur = this.onPreviousDateRangeBlur.bind(this)
		this.recalculateAndReload = this.recalculateAndReload.bind(this)

		this.dateRangeOptions = KOHelpers.getDropdownItems([
			{ id: KOHelpers.datesPresets.yesterday, label: 'Yesterday' },
			{ id: KOHelpers.datesPresets.thisweek, label: 'This week' },
			{ id: KOHelpers.datesPresets.last7days, label: 'Last 7 days' },
			{ id: KOHelpers.datesPresets.lastweek, label: 'Last week' },
			{ id: KOHelpers.datesPresets.monthtodate, label: 'Month to Date' },
			{ id: KOHelpers.datesPresets.last30days, label: 'Last 30 days' },
			{ id: KOHelpers.datesPresets.lastmonth, label: 'Last Month' },
			{ id: KOHelpers.datesPresets.ytd, label: 'Year to Date' },
			{ id: KOHelpers.datesPresets.lastyear, label: 'Last Year' },
			{ id: KOHelpers.datesPresets.custom, label: 'Custom' }
		])

		this.previousDateRangeOptions = KOHelpers.getDropdownItems([
			{ id: '', label: '' },
			{ id: KOHelpers.datesPresets.previousperiod, label: 'Previous Period' },
			{ id: KOHelpers.datesPresets.previousweek, label: 'Previous Week' },
			{ id: KOHelpers.datesPresets.previousmonth, label: 'Previous Month' },
			{ id: KOHelpers.datesPresets.previousyear, label: 'Previous Year' },
			{ id: KOHelpers.datesPresets.previouscustom, label: 'Custom' }
		])

		this.calendarKey = 'cal-key-1'
	}

	getStateFromProps(props) {
		let startDate = props.startDate || moment()
		let endDate = props.endDate || moment()
		let dateCompareAvailable = props.dateCompareAvailable || true
		let usePrevious = props.usePrevious || false
		let currentlySelectingCompareTo = props.currentlySelectingCompareTo || false
		let previousDateRangeType = props.previousDateRangeType ? props.previousDateRangeType : (usePrevious ? 'previouscustom' : '')
		let dateRangeStartDateDisplay = props.dateRangeStartDateDisplay ? props.dateRangeStartDateDisplay :
			(currentlySelectingCompareTo ? this.displayDate(moment(dateRangeStartDateDisplay)) : this.displayDate(moment(startDate)))
		let dateRangeEndDateDisplay = props.dateRangeEndDateDisplay ? props.dateRangeEndDateDisplay :
			(currentlySelectingCompareTo ? this.displayDate(moment(dateRangeEndDateDisplay)) : this.displayDate(moment(endDate)))
		let previousDateRangeStartDateDisplay = props.previousDateRangeStartDateDisplay || ''
		let previousDateRangeEndDateDisplay = props.previousDateRangeEndDateDisplay || ''
		let highlightStartDate = !dateCompareAvailable ? '' : 
			(currentlySelectingCompareTo ? this.comparableDate(moment(dateRangeStartDateDisplay)) : this.comparableDate(moment(previousDateRangeStartDateDisplay)))
		let highlightEndDate = !dateCompareAvailable ? '' : 
			(currentlySelectingCompareTo ? this.comparableDate(moment(dateRangeEndDateDisplay)) : this.comparableDate(moment(previousDateRangeEndDateDisplay)))

		let st = {
			startDate: startDate,
			endDate: endDate,
			dateRangeStartDateDisplay: dateRangeStartDateDisplay,
			dateRangeEndDateDisplay: dateRangeEndDateDisplay,
			previousDateRangeStartDateDisplay: previousDateRangeStartDateDisplay,
			previousDateRangeEndDateDisplay: previousDateRangeEndDateDisplay,
			focusedInput: 'endDate',
			dateRangeType: props.dateRangeType || 'custom',
			previousDateRangeType: previousDateRangeType,
			usePrevious: usePrevious,
			highlightStartDate: highlightStartDate,
			highlightEndDate: highlightEndDate,
			currentlySelectingCompareTo: currentlySelectingCompareTo,
		}

		return st
	}

	recalculateAndReload(dates, reopen = true) {
		let currentlySelectingCompareTo = dates.currentlySelectingCompareTo || this.state.currentlySelectingCompareTo
		if ('endDate' in dates && dates.endDate === null) {
			dates.endDate = dates.startDate
		}
		if (currentlySelectingCompareTo) {
			var dateRangeStartDateDisplay = this.state.dateRangeStartDateDisplay
			var dateRangeEndDateDisplay = this.state.dateRangeEndDateDisplay
			var previousDateRangeStartDateDisplay = 'startDate' in dates ? this.displayDate(dates.startDate) : this.state.previousDateRangeStartDateDisplay
			var previousDateRangeEndDateDisplay = 'endDate' in dates ? this.displayDate(dates.endDate) : this.state.previousDateRangeEndDateDisplay
		} else {
			var dateRangeStartDateDisplay = 'startDate' in dates ? this.displayDate(dates.startDate) : this.state.dateRangeStartDateDisplay
			var dateRangeEndDateDisplay = 'endDate' in dates ? this.displayDate(dates.endDate) : this.state.dateRangeEndDateDisplay
			var previousDateRangeStartDateDisplay = this.state.previousDateRangeStartDateDisplay
			var previousDateRangeEndDateDisplay = this.state.previousDateRangeEndDateDisplay
		}
		let st = {
		  dateRangeType: dates.dateRangeType || this.state.dateRangeType,
		  startDate: dates.startDate || this.state.startDate,
		  endDate: dates.endDate || this.state.endDate,
		  usePrevious: dates.usePrevious || this.state.usePrevious,
		  dateRangeStartDateDisplay: dateRangeStartDateDisplay,
		  dateRangeEndDateDisplay: dateRangeEndDateDisplay,
		  previousDateRangeStartDateDisplay: previousDateRangeStartDateDisplay,
		  previousDateRangeEndDateDisplay: previousDateRangeEndDateDisplay,
		  previousDateRangeType: dates.previousDateRangeType || this.state.previousDateRangeType,
		  currentlySelectingCompareTo: currentlySelectingCompareTo,
		}

		if (this.state.usePrevious && !currentlySelectingCompareTo) {
			if (st.previousDateRangeType !== 'previouscustom') {
				let prevDates = KOHelpers.calculatePreset(
					st.previousDateRangeType,
					moment(st.dateRangeStartDateDisplay),
					moment(st.dateRangeEndDateDisplay)
				)

				st.previousDateRangeStartDateDisplay = this.displayDate(prevDates.newStartDate)
				st.previousDateRangeEndDateDisplay = this.displayDate(prevDates.newEndDate)
			}
			st.highlightStartDate = this.comparableDate(moment(st.previousDateRangeStartDateDisplay))
			st.highlightEndDate = this.comparableDate(moment(st.previousDateRangeEndDateDisplay))
		}
		if (st.currentlySelectingCompareTo) {
			st.highlightStartDate = this.comparableDate(moment(dateRangeStartDateDisplay))
			st.highlightEndDate = this.comparableDate(moment(dateRangeEndDateDisplay))
		}
		if (reopen) {
			this.calendarKey = this.regenCalendarKey(this.calendarKey)
		}
		this.setState(st)
	}

	regenCalendarKey(key) {
		let a = key.split('-')
		a[2] = parseInt(a[2]) + 1
		return a.join('-')
	}

	onDateRangeStartDateChange(event, element) {
		this.changeCurrentlySelecting(false)
		this.setState({dateRangeStartDateDisplay: event.target.value})
	}

	onDateRangeEndDateChange(event, element) {
		this.changeCurrentlySelecting(false)
		this.setState({dateRangeEndDateDisplay: event.target.value})
	}

	dateRangeTypeSelected(event, element) {
		let dateRangeType = element.value
		if (dateRangeType === this.state.dateRangeType) {
			return
		}
		let dates = KOHelpers.calculatePreset(dateRangeType, this.state.startDate, this.state.endDate)
		dates.dateRangeType = dateRangeType
		dates.startDate = dates.newStartDate
		dates.endDate = dates.newEndDate
		this.recalculateAndReload(dates)
	}

	previousDateRangeTypeSelected(event, element) {
		this.selectCompareToPreset(element.value)
	}

	selectCompareToPreset(previousDateRangeType) {
		if (previousDateRangeType === this.state.previousDateRangeType) {
			return
		}

		let st = {
			previousDateRangeType: previousDateRangeType,
			usePrevious: true,
			currentlySelectingCompareTo: true,
			highlightStartDate: this.comparableDate(moment(this.state.dateRangeStartDateDisplay)),
			highlightEndDate: this.comparableDate(moment(this.state.dateRangeEndDateDisplay)),
			startDate: moment(this.state.previousDateRangeStartDateDisplay),
			endDate: moment(this.state.previousDateRangeEndDateDisplay),
		}
		if (previousDateRangeType !== 'previouscustom') {
			let dates = KOHelpers.calculatePreset(
				previousDateRangeType,
				moment(this.state.dateRangeStartDateDisplay),
				moment(this.state.dateRangeEndDateDisplay)
			)

			st.previousDateRangeStartDateDisplay = this.displayDate(dates.newStartDate)
			st.previousDateRangeEndDateDisplay = this.displayDate(dates.newEndDate)
			st.startDate = dates.newStartDate
			st.endDate = dates.newEndDate
		}

		this.recalculateAndReload(st)
	}

	onClose() {
		this.setState({ startDate: this.props.startDate, endDate: this.props.endDate})
		this.props.onDatepickerCancel()
	}

	onApply() {
		let startDate = moment(this.state.dateRangeStartDateDisplay).isValid()
			? moment(this.state.dateRangeStartDateDisplay)
			: (this.state.startDate.isValid() ? this.state.startDate : moment().subtract(7,'d'))
		let endDate = moment(this.state.dateRangeEndDateDisplay).isValid()
			? moment(this.state.dateRangeEndDateDisplay) 
			: startDate

		let dates = {
			startDate: startDate,
			endDate: endDate,
			previousDateRangeStartDateDisplay:this.state.previousDateRangeStartDateDisplay,
			previousDateRangeEndDateDisplay:this.state.previousDateRangeEndDateDisplay,
			usePrevious: this.state.usePrevious,
			dateRangeType: this.state.dateRangeType,
			previousDateRangeType: this.state.previousDateRangeType,
		}

		this.props.onDatepickerApply(dates)
	}

	onDatesChangeHandler(startDate, endDate) {
		let st = {
			startDate: startDate, 
			endDate: endDate,
		}

		if (!this.state.currentlySelectingCompareTo) {
			st.dateRangeStartDateDisplay = this.displayDate(startDate)
			st.dateRangeEndDateDisplay = this.displayDate(endDate)
			st.dateRangeType = 'custom'
		} else {
			st.previousDateRangeStartDateDisplay = this.displayDate(startDate)
			st.previousDateRangeEndDateDisplay = this.displayDate(endDate)
			st.previousDateRangeType = 'previouscustom'
		}

		this.recalculateAndReload(st, false)
	}

	displayDate(momentObject) {
		return momentObject ? momentObject.format('MMM D, YYYY') : ''
	}

	comparableDate(momentObject) {
		return momentObject ? momentObject.format('YYYY-MM-DD') : ''
	}

	/* keep the states in mind -- 
		compare is ckd and we are currently selecting compare; 
		compare is ckd and we are currently NOT selecting it;
		compare is not ckd
	*/
	onToggleCompare() {
		let turningCompareOn = !this.state.usePrevious
		let st = { usePrevious: turningCompareOn }

		if (turningCompareOn) {
			this.changeCurrentlySelecting(true)
			this.selectCompareToPreset('previousperiod')
		} else {
			st.startDate = moment(this.state.dateRangeStartDateDisplay)
			st.endDate = moment(this.state.dateRangeEndDateDisplay)
			st.previousDateRangeType = null
			st.previousDateRangeStartDateDisplay = ''
			st.previousDateRangeEndDateDisplay = ''
			st.currentlySelectingCompareTo = false
			st.highlightStartDate = ''
			st.highlightEndDate = ''
		}
		this.setState(st)
	}

	changeCurrentlySelecting(switchToSelectingCompareTo = true) {
		if (this.state.currentlySelectingCompareTo === switchToSelectingCompareTo) {
			return true
		}

		let st = {
			currentlySelectingCompareTo: !this.state.currentlySelectingCompareTo,
			highlightStartDate: this.state.startDate.format('YYYY-MM-DD'),
			highlightEndDate: this.state.endDate.format('YYYY-MM-DD')
		}

		if (!switchToSelectingCompareTo) {
			st.startDate = moment(this.state.dateRangeStartDateDisplay)
			st.endDate = moment(this.state.dateRangeEndDateDisplay)
		} else {
			st.startDate = moment(this.state.previousDateRangeStartDateDisplay)
			st.endDate = moment(this.state.previousDateRangeEndDateDisplay)
		}

		this.setState(st)
	}

	onPreviousDateRangeStartDateChange(event, element) {
		this.setState({previousDateRangeStartDateDisplay: event.target.value})
	}

	onPreviousDateRangeEndDateChange(event, element) {
		this.setState({previousDateRangeEndDateDisplay: event.target.value})
	}

	isValidDate(str) {
		let m = moment(str)
		return m.isValid()
	}

	onDateRangeBlur(str, field) {
		// this.selectCurr()
		if (this.isValidDate(str)) {
			let st = {
				dateRangeType: 'custom',
				startDate: moment(this.state.dateRangeStartDateDisplay),
				endDate: moment(this.state.dateRangeEndDateDisplay),
			}
			let selectingStart = field === 'startDate'
			if (selectingStart) {
				st.startDate = moment(str)
				if (this.state.dateRangeEndDateDisplay === '') {
					st.endDate = moment(str)
				}
			} else {
				st.endDate = moment(str)
			}
			this.recalculateAndReload(st, selectingStart)
		} else {
			// restore the display
			this.setState({
				dateRangeStartDateDisplay: this.state.startDate.format('MMM D, YYYY'),
				dateRangeEndDateDisplay: this.state.endDate.format('MMM D, YYYY'),
			})
		}
	}

	onPreviousDateRangeBlur(str, field) {
		if (!this.state.usePrevious) {
			return
		}
		if (this.isValidDate(str)) {
			let st = {
				previousDateRangeType: 'previouscustom',
				startDate: moment(this.state.previousDateRangeStartDateDisplay),
				endDate: moment(this.state.previousDateRangeEndDateDisplay),
			}
			let selectingStart = field === 'startDate'
			if (selectingStart) {
				st.startDate = moment(str)
				if (this.state.previousDateRangeEndDateDisplay === '') {
					st.endDate = moment(str)
					st.previousDateRangeEndDateDisplay = str
				}
			} else {
				st.endDate = moment(str)
				st.previousDateRangeEndDateDisplay = str
			}

			// this.recalculateAndReload(st, false)
			this.recalculateAndReload(st, !selectingStart) // TBI
		} else {
			// restore the display
			this.setState({
				previousDateRangeStartDateDisplay: this.state.startDate.format('MMM D, YYYY'),
				previousDateRangeEndDateDisplay: this.state.endDate.format('MMM D, YYYY'),
			})
		}
	}

	selectCurr = () => {
		this.changeCurrentlySelecting(false)
	}

	selectCompareTo = () => {
		this.changeCurrentlySelecting(true)
	}

	render() {
		let previousDateRangeBlock = ''
		let koDatePickerClasses = this.state.currentlySelectingCompareTo ? 'KOdatepicker select-compare-to-range' : 'KOdatepicker '
		if (this.props.dateCompareAvailable) {
			let disablePrevious = !this.state.endDate
			previousDateRangeBlock = (
				<div className={`previous-daterange-section ${this.props.currentlySelectingCompareTo ? 'active' : ''}`}>
					<div className="daterange-dropdown-container">
						<Checkbox
							disabled={disablePrevious}
							checked={this.state.usePrevious}
							onChange={this.onToggleCompare}
							id="date-picker-compare-to-checkbox"
						/>
						<span className="compare-to-label" onClick={this.selectCompareTo}>Compare to</span>
						<KODropdown
							datasource={this.previousDateRangeOptions}
							disabled={disablePrevious}
							selected={this.state.previousDateRangeType}
							onChange={this.previousDateRangeTypeSelected}
							onClick={this.selectCompareTo}
						/>
					</div>
					<div className="daterange-inputs-container">
						<Input
							value={this.state.previousDateRangeStartDateDisplay}
							disabled={disablePrevious}
							onChange={this.onPreviousDateRangeStartDateChange}
							onBlur={(e) => {
								var str = e.target.value
								this.onPreviousDateRangeBlur(str, 'startDate')
							}}
							onFocus={e => {
								this.changeCurrentlySelecting(true)
								this.setState({ previousDateRangeStartDateDisplay: '' })
							}}
						/>
						<Input
							value={this.state.previousDateRangeEndDateDisplay}
							disabled={disablePrevious}
							onChange={this.onPreviousDateRangeEndDateChange}
							onBlur={(e) => {
								var str = e.target.value
								this.onPreviousDateRangeBlur(str, 'endDate')
							}}
							onFocus={e => {
								this.changeCurrentlySelecting(true)
								this.setState({ previousDateRangeEndDateDisplay: '' })
							}}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className={koDatePickerClasses}>
				<div className="KOdatepicker-top-section">
					<ReactDatesDatepicker
						onDatepickerDatesChange={this.onDatesChangeHandler}
						initialStartDate={this.state.startDate}
						initialEndDate={this.state.endDate}
						minimumNights={0}
						currentlySelectingCompareTo={this.state.currentlySelectingCompareTo}
						isDayHighlighted={(day) => { return day.format('YYYY-MM-DD') >= this.state.highlightStartDate && day.format('YYYY-MM-DD') <= this.state.highlightEndDate }}
						key={this.calendarKey}
					/>
				</div>
				<Divider />
				<div className="KOdatepicker-bottom-sections">
					<div className={`daterange-section ${this.props.currentlySelectingCompareTo ? '' : 'active'}`}>
						<div className="daterange-dropdown-container">
							<span className="current-label" onClick={this.selectCurr}>Date Range</span>
							<KODropdown
								datasource={this.dateRangeOptions}
								selected={this.state.dateRangeType}
								onChange={this.dateRangeTypeSelected}
								onClick={this.selectCurr}
							/>
						</div>
						<div className="daterange-inputs-container">
							<Input
								value={this.state.dateRangeStartDateDisplay}
								onChange={this.onDateRangeStartDateChange}
								onBlur={(e) => {
									var str = e.target.value
									this.onDateRangeBlur(str, 'startDate')
								}}
								onFocus={e => {
									this.changeCurrentlySelecting(false)
									this.setState({ dateRangeStartDateDisplay: '' })
								}}
							/>
							<Input
								value={this.state.dateRangeEndDateDisplay}
								onChange={this.onDateRangeEndDateChange}
								onBlur={(e) => {
									var str = e.target.value
									this.onDateRangeBlur(str, 'endDate')
								}}
								onFocus={e => {
									this.changeCurrentlySelecting(false)
									this.setState({ dateRangeEndDateDisplay: '' })
								}}
							/>
						</div>
					</div>
					{previousDateRangeBlock}
					<div className="date-picker-actions">
						<Button className="filters" basic color="grey" onClick={this.onClose}>
							CANCEL
						</Button>
						<Button className="customize-columns-button" primary onClick={this.onApply}>
							APPLY
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

KODatepicker.propTypes = {
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	previousDateRangeStartDateDisplay: PropTypes.string,
	previousDateRangeEndDateDisplay: PropTypes.string,
	onDatepickerApply: PropTypes.func,
	usePrevious: PropTypes.bool,
}

KODatepicker.defaultProps = {
	startDate: {},
	endDate: {},
	previousDateRangeStartDateDisplay: '',
	previousDateRangeEndDateDisplay: '',
	onDatepickerApply: () => {},
	usePrevious: false,
}

export default KODatepicker

import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'

class Privacy extends React.Component {
	render() {
		return (
			<React.Fragment>
				<HeaderNoNav />
				<div className="template-content">
					<div className="content">
						<div className="privacy-container-area-holder">
							<h2>Privacy</h2>
							<div className="text-container">
								<span>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet metus in dui
									posuere posuere. Vestibulum pretium laoreet venenatis. Donec vel gravida leo, sed
									malesuada dolor. Donec sit amet egestas dolor. Nunc at fermentum nunc. Aliquam
									porttitor pretium nibh non porta. Vestibulum ante ipsum primis in faucibus orci
									luctus et ultrices posuere cubilia Curae; Aliquam malesuada nisl eget laoreet
									suscipit. Praesent et bibendum neque. Pellentesque tristique est at tincidunt
									cursus.
								</span>
								<br />
								<span>
									Sed venenatis lacus libero, a mollis felis laoreet at. Quisque facilisis cursus
									ex, vitae imperdiet urna suscipit in. Curabitur ex ligula, placerat condimentum
									ante ornare, tempus vulputate ex. Suspendisse iaculis felis sit amet tellus
									ornare, sit amet convallis libero interdum. Sed libero erat, vestibulum in orci
									ut, maximus lacinia ex. Curabitur rhoncus pulvinar nisl ut imperdiet. Ut dignissim
									sodales massa, a lacinia tellus lacinia eu. Ut sollicitudin mollis metus ac
									sodales. Sed id ante libero. Aenean ultricies interdum metus. Quisque placerat, ex
									ut posuere molestie, arcu arcu faucibus metus, sed finibus dolor massa id ipsum.
									Morbi scelerisque accumsan pulvinar. Integer eget neque eget dolor tincidunt
									suscipit. Maecenas eu congue leo. Duis facilisis leo quis neque imperdiet
									scelerisque. Nunc ultricies ex ac ipsum ultricies pellentesque sed at lectus.
									Pellentesque volutpat consequat risus, at tristique leo pretium non. Sed lectus
									velit, tempus non elit vitae, tempor blandit ipsum.
								</span>
								<br />
								<span>
									Sed malesuada sapien turpis. Morbi eu tortor ipsum. Mauris euismod at mauris in
									cursus. Sed volutpat, nulla sit amet molestie consequat, quam dui condimentum
									tortor, vel laoreet tortor leo sed dolor. Donec iaculis sollicitudin turpis eget
									porttitor. Aliquam molestie mauris nec risus maximus vehicula. Pellentesque non
									dolor aliquet, volutpat magna sed, viverra arcu. Suspendisse sollicitudin ante in
									cursus ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
									scelerisque, risus eu porta iaculis, justo nunc faucibus sem, finibus sollicitudin
									erat arcu quis turpis. Sed dignissim id sapien a dapibus. Nulla facilisis tellus
									non sagittis posuere. Nunc elementum, lectus at bibendum sagittis, justo mauris
									consequat mi, ut fermentum mauris enim non velit. Proin vitae vulputate lorem.
									Proin consequat pulvinar sagittis. Vestibulum laoreet viverra nisi non commodo.
									Phasellus lacus urna, consequat sed viverra ac, semper blandit neque. Donec ac
									turpis quis urna laoreet eleifend et ut ex. Proin commodo neque non lectus
									eleifend, eu maximus leo eleifend. Phasellus sed elit et nunc dignissim sagittis.
									Vestibulum auctor, ligula sed gravida pellentesque, felis diam molestie arcu, eu
									pharetra quam justo in justo. Nam in porta mauris. Nunc dui odio, accumsan sed
									tortor eget, suscipit efficitur arcu. Cras eget arcu eget nisi laoreet finibus
									quis ac justo. Nam non hendrerit justo, non volutpat lectus. Suspendisse sed
									gravida neque. In non faucibus risus, a viverra sem. Nulla facilisi.
								</span>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</React.Fragment>
		)
	}
}

export default Privacy

/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import { forbidExtraProps } from 'airbnb-prop-types'
import moment from 'moment'
import omit from 'lodash/omit'

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
const HORIZONTAL_ORIENTATION = 'horizontal'

//import DayPickerRangeController from '../src/components/DayPickerRangeController'

import { DateRangePicker, DayPickerRangeController } from 'react-dates'

//import ScrollableOrientationShape from '../src/shapes/ScrollableOrientationShape'

//import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION } from 'constants'
//import isInclusivelyAfterDay from '../src/utils/isInclusivelyAfterDay'

const propTypes = forbidExtraProps({
	// example props for the demo
	autoFocusEndDate: PropTypes.bool,
	initialStartDate: momentPropTypes.momentObj,
	initialEndDate: momentPropTypes.momentObj,
	startDateOffset: PropTypes.func,
	endDateOffset: PropTypes.func,
	showInputs: PropTypes.bool,
	minDate: momentPropTypes.momentObj,
	maxDate: momentPropTypes.momentObj,

	keepOpenOnDateSelect: PropTypes.bool,
	minimumNights: PropTypes.number,
	isOutsideRange: PropTypes.func,
	isDayBlocked: PropTypes.func,
	isDayHighlighted: PropTypes.func,

	// DayPicker props
	enableOutsideDays: PropTypes.bool,
	numberOfMonths: PropTypes.number,

	verticalHeight: PropTypes.number,
	withPortal: PropTypes.bool,
	initialVisibleMonth: PropTypes.func,
	renderCalendarInfo: PropTypes.func,
	renderMonthElement: PropTypes.func,
	renderMonthText: PropTypes.func,

	navPrev: PropTypes.node,
	navNext: PropTypes.node,

	onPrevMonthClick: PropTypes.func,
	onNextMonthClick: PropTypes.func,
	onOutsideClick: PropTypes.func,
	renderCalendarDay: PropTypes.func,
	renderDayContents: PropTypes.func,
	renderKeyboardShortcutsButton: PropTypes.func,

	// i18n
	monthFormat: PropTypes.string,

	isRTL: PropTypes.bool
})

const defaultProps = {
	// example props for the demo
	autoFocusEndDate: false,
	initialStartDate: null,
	initialEndDate: null,
	startDateOffset: undefined,
	endDateOffset: undefined,
	showInputs: false,
	minDate: null,
	maxDate: null,

	// day presentation and interaction related props
	renderCalendarDay: undefined,
	renderDayContents: null,
	minimumNights: 1,
	isDayBlocked: () => false,
	isOutsideRange: () => false,
	isDayHighlighted: () => false,
	enableOutsideDays: false,

	// calendar presentation and interaction related props

	verticalHeight: undefined,
	withPortal: false,
	initialVisibleMonth: null,
	numberOfMonths: 3,
	onOutsideClick() {},
	keepOpenOnDateSelect: false,
	renderCalendarInfo: null,
	isRTL: false,
	renderMonthText: null,
	renderMonthElement: null,
	renderKeyboardShortcutsButton: undefined,

	// navigation related props
	navPrev: null,
	navNext: null,
	onPrevMonthClick() {},
	onNextMonthClick() {},

	// internationalization
	monthFormat: 'MMMM YYYY'
}

class ReactDatesDatepicker extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
			startDate: props.initialStartDate,
			endDate: props.initialEndDate
		}

		this.onDatesChange = this.onDatesChange.bind(this)
		this.onFocusChange = this.onFocusChange.bind(this)
		this.onMonthClick = this.onMonthClick.bind(this)
		this.initialVisibleMonth = this.initialVisibleMonth.bind(this)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		console.log('ReactDatesDatePicker:componentWillReceiveProps:', nextProps)

		if (
			nextProps.initialStartDate !== this.state.startDate ||
			nextProps.initialEndDate !== this.state.endDate
		) {
			console.log('WRP:np:', nextProps.initialStartDate, nextProps.initialEndDate)
			console.log('WRP:st:', nextProps.initialStartDate, nextProps.initialStartDate)
			this.setState({
				startDate: nextProps.initialStartDate,
				endDate: nextProps.initialEndDate
			})
		}
	}

	onDatesChange({ startDate, endDate }) {
		this.setState({ startDate, endDate })
		this.props.onDatepickerDatesChange(startDate, endDate)
	}

	onFocusChange(focusedInput) {
		this.setState({
			// Force the focusedInput to always be truthy so that dates are always selectable
			focusedInput: !focusedInput ? START_DATE : focusedInput
		})

		console.log('focusedInput', focusedInput)
	}

    monthCaptionComponent(month) {
        return (
            <div className="datepicker-month-caption" onClick={() => {this.onMonthClick(month)}}>
                {month.format('MMMM YYYY')}
            </div>
        )
    }

    onMonthClick(month) {
    	let m = month.clone()
    	let s = m.startOf('month')
    	let e = month.endOf('month')
    	this.setState({ startDate: s, endDate: e })
		this.props.onDatepickerDatesChange(s, e)
    }

    initialVisibleMonth(month) {
    	let m = this.props.currentlySelectingCompareTo ? this.state.endDate.clone() : this.state.startDate.clone()
    	return m.subtract(1, 'months')
    }

	render() {
		const { showInputs } = this.props
		const { focusedInput, startDate, endDate } = this.state

		const props = omit(this.props, [
			'autoFocus',
			'autoFocusEndDate',
			'initialStartDate',
			'initialEndDate',
			'showInputs',
			'onDatepickerDatesChange',
			'currentlySelectingCompareTo',
		])

		const startDateString = startDate && startDate.format('YYYY-MM-DD')
		const endDateString = endDate && endDate.format('YYYY-MM-DD')

		return (
			<div style={{ height: '100%' }}>
				{showInputs && (
					<div style={{ marginBottom: 16 }}>
						<input type="text" name="start date" value={startDateString} readOnly />
						<input type="text" name="end date" value={endDateString} readOnly />
					</div>
				)}

				<DayPickerRangeController
					{...props}
					onDatesChange={this.onDatesChange}
					onFocusChange={this.onFocusChange}
					focusedInput={focusedInput}
					startDate={startDate}
					isDayHighlighted={this.props.isDayHighlighted}
					endDate={endDate}
					renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {return this.monthCaptionComponent(month)}}
					initialVisibleMonth={this.initialVisibleMonth}
					hideKeyboardShortcutsPanel={true}
				/>
			</div>
		)
	}
}

ReactDatesDatepicker.propTypes = propTypes
ReactDatesDatepicker.defaultProps = defaultProps

export default ReactDatesDatepicker

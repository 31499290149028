import React from 'react'
import Header from 'ko_core/components/organisms/Header/Header'
import NavigationContainer from 'ko_core/components/organisms/NavigationContainer/NavigationContainer'
import NavigationHelper from 'ko_core/components/atoms/helpers/NavigationHelper/NavigationHelper'
import navigationLabels from 'ko_core/helpers/navigation.labels'

const gridMap = {
	ppc_adcopies: 'ppc.adcopy',
	ppc_adgroups: 'ppc.adgroup',
	ppc_campaigns: 'ppc.campaign',
	ppc_keywords: 'ppc.keyword',
	ppc_publishers: 'ppc.publisher',
	seo_keywords: 'seo.keyword',
	seo_urls: 'seo.url',
}

function getHeaderComponent(props) {
	let isAdmin = props.sessionData.user.is_user_admin == "1" || props.sessionData.user.is_user_admin == "true"
	let profileInfo = {
		name: props.sessionData.user.fullname,
		email: props.sessionData.user.email
	}

	return (
		<Header
			clientSubdomains={props.sessionData.configuration.multidomain}
			isAdmin={isAdmin}
			profileInfo={profileInfo}
			accountRep={props.sessionData.configuration.account_rep}
			logoImage={props.sessionData.configuration.logo_image}
			emailSupport={props.sessionData.configuration.email_support}
			notifications={props.sessionData.notifications}
		/>
	)
}

function getNavigationComponent(props) {
	return (
		<NavigationContainer
			sectionsSource={props.koSections}
			blacklistedApps={props.blacklistedApps}
			blacklistedViews={props.blacklistedViews}
			selectedSectionId={props.viewConfig.view.navigationParent || props.viewConfig.view.navigationAppId}
			sectionLinkSelectedId={props.viewConfig.view.navigationId}
			logoImage={props.sessionData.configuration.logo_image}
		/>
	)
}

function getGridConfigs(viewConfig, blacklistedViews) {
	if (!('grid' in viewConfig && 'configs' in viewConfig.grid)) {
		return {}
	}
	const forbiddenGrids = getForbiddenGrids(blacklistedViews)
	const app = viewConfig.view.navigationAppId
	let gridConfigs = {}
	for (let gridName in viewConfig.grid.configs) {
		if (forbiddenGrids.indexOf(app + '.' + gridName) < 0) {
			gridConfigs[gridName] = viewConfig.grid.configs[gridName]
		}
	}

	return gridConfigs
}

function getForbiddenGrids(blacklistedViews) {
	let forbiddenGrids = []

	blacklistedViews.forEach(viewName => {
		if (viewName in gridMap) {
			forbiddenGrids.push(gridMap[viewName])
		}
	})

	return forbiddenGrids
}

function getLinkObject(key, blacklist) {
	if (blacklist.views.indexOf(key) > -1) {
		return null
	}
	let linkObject = NavigationHelper.getLinkObjectFromKey(key, blacklist)
	if (linkObject) {
		if (blacklist.apps.indexOf(linkObject.app) < 0) {
			return linkObject
		}
	}

	return null
}

export default {
	gridMap: gridMap,
	getHeaderComponent: getHeaderComponent,
	getNavigationComponent: getNavigationComponent,
	getGridConfigs: getGridConfigs,
	getLinkObject: getLinkObject,
}
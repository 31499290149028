import React from 'react'
import PropTypes from 'prop-types'

import NavigationSectionIcon from '../NavigationSectionIcon/NavigationSectionIcon';

const NavigationSectionLink = (props) => {
	return (
		<div className='section-header'>
			<NavigationSectionIcon key={props.sectionTitle} name={props.sectionTitle} active={props.sectionActive} />
			<a href={props.sectionLink}><div className='navigation-section-title caption2 noselect'>{props.sectionTitle}</div></a>
		</div>
	)
}

NavigationSectionLink.propTypes = {
	sectionTitle: PropTypes.string,
	sectionLink: PropTypes.string,
	sectionActive: PropTypes.bool
}

NavigationSectionLink.defaultProps = {
	sectionTitle: '',
	sectionLink: '',
	sectionActive: false
}

export default NavigationSectionLink


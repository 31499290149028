import React from 'react'
import { PropTypes } from 'prop-types'

import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import { Form, Button, Input, Container, Divider, Grid } from 'semantic-ui-react'
import axios from 'axios';

class crossSiteLogin extends React.Component {
	constructor(props) {
		super(props)
console.log('jbf', props)
		let referrer = new URL(window.location.href);
		referrer = referrer.searchParams.get('referrer');
console.log('jbf referrer', referrer)
		this.token = props.sessionData.configuration.token || ''
		let environment = props.sessionData.configuration.env || ''
		let entryPoint = environment === 'dev' ? '/dev.php' : ''
		this.formEndpoint = entryPoint + '/login'
		this.clientLogo = props.sessionData.configuration.logo_image || ''
		this.state = {
			username: '',
			password: '',
			error: '',
			redirectUrl: '',
			referrer: referrer,
			authenticated: false,
			loading: false
		  };

		this.doAutoSubmit = this.doAutoSubmit.bind(this);
	}

	componentDidMount() {
		if ('public_key' in this.props.route.match.match.params) {
			this.doAutoSubmit()
		}
	}

	doAutoSubmit() {
		let a = this.props.route.match.match.params.parameters
		let b = this.props.route.match.match.params.public_key
		let app = this
		console.log('jbf', a, b)
		this.setState({loading: true})
		axios.post(this.formEndpoint, { public_key: b, parameters: a })
			.then(function (response) {
				if (response.status === 200) {
					console.log(response.data)
					app.setState({
						redirectUrl: response.data.redirect_url,
						authenticated: true,
						loading: false,
					})
					window.location.replace(response.data.redirect_url);
				}
				else {
					app.setState({
						redirectUrl: '/login', 
						loading: false
					})
					window.location.replace('/login')
				}
			})
			.catch(function (error) {
				console.log(error);
				app.setState({
					redirectUrl: '/login', 
					loading: false
				})
				window.location.replace('/login')
			});
	}

	render() {
		return ('')
	}
}

export default crossSiteLogin

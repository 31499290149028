import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

class KODropdown extends React.Component {
	constructor(props) {
		super(props)

		let options = this.getSelectorOptions(props.datasource)
		let selected = this.props.selected

		this.state = {
			options,
			selected
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.datasource.length !== this.props.datasource.length) {
			let options = this.getSelectorOptions(nextProps.datasource)
			this.setState({
				options
			})
		}

		if (nextProps.selected !== this.props.selected) {
			this.setState({
				selected: nextProps.selected
			})
		}
	}

	getSelectorOptions(options) {
		let selectorOptions = []
		for (let i = 0; i < options.length; i++) {
			const option = options[i]
			let item = {}
			item.key = i
			item.text = option.text
			item.value = option.value
			selectorOptions.push(item)
		}

		//console.log('getSelectorOptions', options, selectorOptions)
		return selectorOptions
	}

	render() {
		return (
			<Dropdown
				selection
				options={this.state.options}
				value={this.state.selected}
				disabled={this.props.disabled}
				onChange={this.props.onChange}
				onClick={this.props.onClick}
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				placeholder={this.props.placeholder}
				search={this.props.search}
				error={this.props.error}
				style={this.props.style}
			/>
		)
	}
}

KODropdown.propTypes = {
	datasource: PropTypes.array,
	selected: PropTypes.node,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	search: PropTypes.bool,
	error: PropTypes.bool,
	style: PropTypes.object
}

KODropdown.defaultProps = {
	datasource: [],
	selected: '',
	onChange: null,
	onClick: null,
	onOpen: null,
	onClose: null,
	disabled: false,
	placeholder: '',
	search: false,
	error: false,
	style: {}
}

export default KODropdown

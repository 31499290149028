import React, { Component } from 'react'
import './App.css'
import 'ko_core/semantic/dist/semantic.min.css'

import configHelper from './views/viewsConfigHelper'

import NonavViews from './views/nonav/nonav.views.config'
import PageNotFound from './views/nonav/PageNotFound'

//Routing
import { createBrowserHistory } from 'history'

let reactRouter = require('react-router-dom')
var Router = reactRouter.Router
var Route = reactRouter.Route
var Switch = reactRouter.Switch
let historyFromParam = createBrowserHistory()
let enabledApps = [NonavViews]
let sessionData = {}

class App extends Component {
	constructor(props) {
		super(props)
		sessionData = props.sessionData
	}

	render() {
		let allAppsConfig = configHelper.getAllAppsConfig(enabledApps)
		let appRoutes = allAppsConfig.routes
		let componentList = allAppsConfig.componentList

		return (
			<Router history={historyFromParam}>
				<div className="App login">
					<Switch>
						{appRoutes.map((route) => (
							<Route
								exact
								key={route.fullpath}
								path={`/${route.fullpath}`}
								render={(props) => {
									return React.createElement(componentList[route.component], {
										...this.props,
										route: {
											props: { ...route },
											match: { ...props }
										}
									})
								}}
								sessionData={sessionData}
							/>
						))}
						<Route render={(props) => {
							return React.createElement(PageNotFound, {
								...this.props,
							})
						}}/>
					</Switch>
				</div>
			</Router>
		)
	}
}

export default App

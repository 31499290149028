import React from 'react'
import HeaderNoNav from 'ko_core/components/organisms/HeaderNoNav/HeaderNoNav'
import Footer from 'ko_core/components/molecules/Footer/Footer'
import { Button, Input, Container, Divider, Grid } from 'semantic-ui-react'
import { NonavApi } from '../../../services/NonavApi'
// import KODropdown from 'ko_core/components/molecules/KODropdown/KODropdown'
import KOHelpers from 'ko_core/helpers/ko.helpers'
import Enzoic from '@enzoic/enzoic-react-password-strength'

class ForgotPasswordLanding extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			password: null,
			passwordConfirm: null,
			resetPasswordMessage: null,
			hasError: false,
			loading: false,
			breached: false
		}

		this.forgotTypesDropdown = KOHelpers.getDropdownItems([
			{ id: 'email', label: 'Email' },
			{ id: 'username', label: 'Username' }
		])

		this.clientLogo = props.sessionData.configuration.logo_image || ''

		this.token = props.sessionData.configuration.token || ''
		this.userid = props.sessionData.forgot_password.userid || ''
		this.username = props.sessionData.forgot_password.username || ''
		this.parameter = props.sessionData.forgot_password.parameter || ''
		this.public_key = props.sessionData.forgot_password.public_key || ''

		this.onPasswordChange = this.onPasswordChange.bind(this)
		this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this)
		this.onTypeChangeHandler = this.onTypeChangeHandler.bind(this)
		this.submitNewPassword = this.submitNewPassword.bind(this)
	}

	onTypeChangeHandler(event, element) {
		// console.log('onTypeChangeHandler', element, event)
		// let text_type = element.value
		// console.log('onInputNameChange', element, event)
		// this.setState({ text_type })
	}

	onPasswordChange(enzoic) {
		let password = enzoic.password
		let breached = (enzoic.score === 0)
		//console.log('onPasswordChange', enzoic)
		this.setState({ password, breached })
	}

	onPasswordConfirmChange(event, element) {
		let passwordConfirm = element.value
		//console.log('jbf onInputNameChange', element, event)
		this.setState({ passwordConfirm })
	}

	submitNewPassword() {
		this.setState({loading: true})
		let valid = this.validatePassword()

		if (!valid) {
			return
		}

		let params = {
			'UsersBackend[id]': this.userid,
			'UsersBackend[_csrf_token]': this.token,
			'UsersBackend[encrypted_password]': this.state.password,
			'UsersBackend[confirm_password]':  this.state.passwordConfirm,
			'parameter': this.parameter,
			'public_key': this.public_key,
		}

		//NonavApi.submitNewPasswordService(params).then((data) => {
		//	console.log('submitNewPassword::response::data', data)
			// if (data.data.flash) {
			// 	this.setState({ resetPasswordMessage: data.data.flash })
			// }
		//})




		//console.log('Post response:')
				//console.log(response);
				let app = this
				NonavApi.submitNewPasswordService(params).then((data) => {
				if (data.status === 200) {
					//console.log("Login successfull")
					//console.log(response.data.redirect_url)

					// Check for errors
					console.log(data.data.errors)
					if (data.data.errors === undefined) {
						
						app.setState({
							resetPasswordMessage: 'Your password has been updated',
							loading: false,
							hasError: false
						})
					} else {
						let errorKey = Object.keys(data.data.errors)[0]
						let errorMessage = data.data.errors[errorKey]
						console.log(errorMessage)
						app.setState({
							resetPasswordMessage: errorMessage,
							loading: false,
							hasError: true
						})
					}

					
					// @todo: update to react redirect?
					//window.location.replace(response.data.redirect_url);
				}
				else {
					//console.log("Invalid username and/or password!");
					app.setState({
						resetPasswordMessage:'Invalid password', 
						loading: false,
						hasError: true
					})
				}
			})
			.catch(function (error) {
				//console.log(error);
				app.setState({
					resetPasswordMessage:'Invalid password', 
					loading: false,
					hasError: true
				})
			});

	}

	validatePassword = () => {
		const regexSeqNumUp = RegExp(/(?:0(?=1|\b)|1(?=2|\b)|2(?=3|\b)|3(?=4|\b)|4(?=5|\b)|5(?=6|\b)|6(?=7|\b)|7(?=8|\b)|8(?=9|\b)|9\b){4}/);
		const regexSeqNumDown = RegExp(/(?:9(?=8|\b)|8(?=7|\b)|7(?=6|\b)|6(?=5|\b)|5(?=4|\b)|4(?=3|\b)|3(?=2|\b)|2(?=1|\b)|1(?=0|\b)|0\b){4}/);
		const regexSeqAlphaUp = RegExp(/(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)+/);
		const regexSeqAlphaDown = RegExp(/(zyxw|yxwv|xwvu|wvut|vuts|utsr|tsrq|srqp|rqpo|qpon|ponm|onml|nmlk|mlkj|lkji|kjih|jihg|ihgf|hgfe|gfed|fedc|edcb|dcba)+/);
		const repeatedChars = RegExp(/(.)\1{3}/);

		let password = this.state.password
		let passwordConfirm = this.state.passwordConfirm
		let breached = this.state.breached

		if (!password) {
			this.setState({ 
				resetPasswordMessage: 'Passwords cannot be empty',
				loading: false,
				hasError: true
			})
			return false;
		} else if (password !== passwordConfirm) {
			this.setState({ 
				resetPasswordMessage: 'Passwords do not match',
				loading: false,
				hasError: true
			})
			return false;
		} else if (password.length < 8 || password.length > 64) {
			this.setState({ 
				resetPasswordMessage: 'Password must be between 8 to 64 characters',
				loading: false,
				hasError: true
			})
			return false;
		}
		else if (regexSeqNumUp.test(password) ||
			regexSeqNumDown.test(password) ||
			regexSeqAlphaUp.test(password) ||
			regexSeqAlphaDown.test(password)
		 ) {
			this.setState({ 
				resetPasswordMessage: 'Password cannot contain sequential characters (e.g: 1234, 4321, abcd, dcba, etc)',
				loading: false,
				hasError: true
			})
			return false;
		}
		else if (repeatedChars.test(password)) {
			this.setState({ 
				resetPasswordMessage: 'Password cannot contain more than 3 repeated characters',
				loading: false,
				hasError: true
			})
			return false;
		} else if (breached) {
			this.setState({ 
				resetPasswordMessage: 'Password entered is compromised!',
				loading: false,
				hasError: true
			})
			return false;
		} else {
			return true;
		}
	}

	render() {
		let renderMessage
		/*if (this.state.resetPasswordMessage != null) {
			renderMessage = (
				<React.Fragment>
					<div>
						<span>{this.state.resetPasswordMessage}</span>
					</div>
					<div className="forgot-pass-container">
						<a className="forgot-pass-link" href="login">
							Back to login
						</a>
					</div>
				</React.Fragment>
			)
		} else {*/

			let renderMessageClass = this.state.hasError ? 'error' : 'success'
			renderMessage = (
				<React.Fragment>
					<Grid.Row>
						{this.state.resetPasswordMessage && 
							<div className={renderMessageClass}>
								{this.state.resetPasswordMessage}
							</div>
						}
					</Grid.Row>
					<Grid.Row>
						<div className="inputs-container">
							<input type="hidden" name="UsersBackend[id]" value={this.userid} id="UsersBackend_id" />
							<input type="hidden" name="UsersBackend[_csrf_token]" value={this.token} id="UsersBackend__csrf_token" />	
							<input type="hidden" id="" value={this.parameter} name="parameter" />
							<input type="hidden" id="" value={this.public_key} name="public_key" />
							<Grid centered columns={2}>
								<Grid.Column className="field" floated="right">
									<label>New password</label>
									<Enzoic
										className="ui fluid input"
										style={{ /*display: 'none'*/ }}
										minLength={5}
										minScore={4}
										scoreWords={['hacked', 'weak', 'okay', 'good', 'strong', 'stronger']}
										changeCallback={this.onPasswordChange}
										inputProps={{ autoComplete: "off" }}
									/>
									{/*<Input placeholder="" type="password" onChange={this.onPasswordChange} fluid />*/}
								</Grid.Column>
								<Grid.Column className="field">
									<label>Confirm password</label>
									<div className="">
										<Input placeholder="" type="password" onChange={this.onPasswordConfirmChange} fluid />
									</div>
								</Grid.Column>
							</Grid>
						</div>
					</Grid.Row>
					<Grid.Row className="submit-container">
						<Grid.Column>
							<a className="forgot-pass-link" href="login">
								Back to login
							</a>
							<Button loading={this.state.loading} onClick={this.submitNewPassword}>Reset Password</Button>
						</Grid.Column>
					</Grid.Row>
				</React.Fragment>
			)
		//}
		return (
			<React.Fragment>
				<HeaderNoNav />
				<Grid centered columns={3} verticalAlign="middle" className="login-container">
					<Grid.Column verticalAlign="middle" className="login-box">
						<div className="nonav-template-content">
							<div className="content">
								<Container>
									<div className="login">
										<div className="info">
											<div className="logo-container">
												<div className="client-logo">
													<img className="logo" src={this.clientLogo} alt="" border="0" />
												</div>
											</div>
											<div className="description-container">
												<span className="description">
												Please Enter a New Password in the Fields Below for User: <span className="username">{this.username}</span>
												</span>
											</div>
										</div>
										<Divider />
										<div className="form-container">{renderMessage}</div>
									</div>
								</Container>
							</div>
						</div>
					</Grid.Column>
				</Grid>
				<Footer />
			</React.Fragment>
		)
	}
}

export default ForgotPasswordLanding

import React from 'react'
import PropTypes from 'prop-types'

const NavigationSectionIcon = (props) => {
	function getIconAppClass(iconName) {
		//console.log('getIco:', iconName)
		switch (iconName) {
			case 'PPC PERFORMANCE':
				return 'ppc-performance'

			case 'SEO PERFORMANCE':
				return 'seo-performance'

			case 'PPC + SEO Synergy':
				return 'synergy'

			case 'REPORTS':
				return 'reports'

			case 'PPC AUDITING':
				return 'audience'

			case 'MULTI-CHANNEL ANALYSIS':
				return 'multichannel-analysis'

			case 'SEO OPPORTUNITIES':
				return 'seo-opportunities'

			default:
				break;
		}
	}

	let iconClass = 'koicon-'
	let active = (props.active ? '_orange' : '_grey')
	iconClass = iconClass + getIconAppClass(props.name) + active

	//console.log(iconClass, props, props.name, props.active)
	//return getIcon(props.name)
	//<IconSeo fill={'#FF4611'} />
	return (
		<div className={`icon koicon ${iconClass}`}></div>
	)


}

NavigationSectionIcon.propTypes = {
	name: PropTypes.string,
	active: PropTypes.bool
}

NavigationSectionIcon.defaultProps = {
	name: '',
	active: false
}

export default NavigationSectionIcon

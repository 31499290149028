import React from 'react'
import PropTypes from 'prop-types'

const MenuIcon = (props) => {
	let iconClass = 'koicon-'
	let active = props.active ? '_active' : '_default'
	iconClass = iconClass + props.name + active

	//console.log(iconClass, props, props.name, props.active)
	//return getIcon(props.name)
	//<IconSeo fill={'#FF4611'} />
	return (
		<div className="menu-icon-container" onClick={props.onClick}>
			<div className={`icon koicon ${iconClass}`} />
			{props.children}
		</div>
	)
}

MenuIcon.propTypes = {
	name: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func
}

MenuIcon.defaultProps = {
	name: '',
	active: false,
	onClick: () => {}
}

export default MenuIcon

import React from 'react'
import PropTypes from 'prop-types'
import NavigationSection from 'ko_core/components/molecules/NavigationSection/NavigationSection'
import NavigationSearch from 'ko_core/components/molecules/NavigationSearch/NavigationSearch'
import NavigationSectionLink from 'ko_core/components/molecules/NavigationSectionLink/NavigationSectionLink'

const Navigation = (props) => {
	function getSelectedSections(sectionId) {
		let sections = ['seo']
		let sectionOpen = 1
		// eslint-disable-next-line
		sections.map((section) => {
			//console.log('compareSectionNames::', sectionId, section)
			if (section === sectionId) {
				//console.log('open', sectionId)
				sectionOpen = 0
			}
		})
		//console.log(sectionId, '::', sectionOpen)
		return sectionOpen
		//props.selectedSections
	}

	function isSectionActive(selectedSections, sectionId) {
		for (let i = 0; i < selectedSections.length; i++) {
			if (selectedSections[i] === sectionId) {
				return true
			}
		}

		return false
	}

	function renderNavigationSection(section, props, autoScrollDown) {
		// eslint-disable-next-line
		if (section.sectionGroup != null && section.sectionGroup === true) {
			return (
				<NavigationSection
					key={section.label}
					sectionTitle={section.label}
					sectionLinks={section.links}
					sectionOpen={getSelectedSections(section.id)}
					sectionActive={isSectionActive(props.sectionsOpen, section.id)}
					sectionIsActive={props.selectedSectionId === section.id}
					sectionId={section.id}
					sectionLinkSelectedId={props.sectionLinkSelectedId}
					sectionOpenClickHandler={props.sectionOpenClickHandler}
					autoScrollDown={autoScrollDown}
				/>
			)
		} else {
			return (
				<NavigationSectionLink
					key={section.label}
					sectionTitle={section.label}
					sectionLink={section.url}
				/>
			)
		}
	}

	return (
		<div className="navigation-container">
			<div className="presentation-section">
				{/* acronym logo */}
				<div className="header-info-container acronym-logo">
					<a href="https://www.acronym.com" target="_blank">
						<div className="logo" />
					</a>
				</div>
				{/* client logo */}
				<div className="header-info-container client-logo">
					<img className="logo" src={props.logoImage} />
				</div>
			</div>
			<NavigationSearch 
				blacklistedApps={props.blacklistedApps}
				blacklistedViews={props.blacklistedViews}
			/>
			<div id="navigation-menu-container" className="navigation-menu">
				{props.sections.map(function(section, index) {
					// Auto Scroll on index element > second to last
					const autoScrollDown = (typeof props.sections[index+2] === 'undefined')
					return renderNavigationSection(section, props, autoScrollDown)
				})}
			</div>
		</div>
	)
}

Navigation.propTypes = {
	sections: PropTypes.array,
	selectedSectionId: PropTypes.string,
	sectionLinkSelectedId: PropTypes.string,
	logoImage: PropTypes.string,
}

Navigation.defaultProps = {
	sections: [],
	selectedSectionId: '',
	sectionLinkSelectedId: '',
	logoImage: '',
}

export default Navigation
